// Register Driver User Type
export const REGISTER_DRIVER_REQUEST = "REGISTER_DRIVER_REQUEST";
export const REGISTER_DRIVER_SUCCESS = "REGISTER_DRIVER_SUCCESS";
export const REGISTER_DRIVER_ERROR = "REGISTER_DRIVER_ERROR";

// Login Driver User Type
export const LOGIN_DRIVER_REQUEST = "LOGIN_DRIVER_REQUEST";
export const LOGIN_DRIVER_SUCCESS = "LOGIN_DRIVER_SUCCESS";
export const LOGIN_DRIVER_FAIL = "LOGIN_DRIVER_FAIL";

// Driver User Details Type
export const DRIVER_USER_DETAIL_REQUEST = "DRIVER_USER_DETAIL_REQUEST";
export const DRIVER_USER_DETAIL_SUCCESS = "DRIVER_USER_DETAIL_SUCCESS";
export const DRIVER_USER_DETAIL_FAIL = "DRIVER_USER_DETAIL_FAIL";

// Update Driver users Type
export const UPDATE_DRIVER_REQUEST = "UPDATE_DRIVER_REQUEST";
export const UPDATE_DRIVER_SUCCESS = "UPDATE_DRIVER_SUCCESS";
export const UPDATE_DRIVER_RESET = "UPDATE_DRIVER_RESET";
export const UPDATE_DRIVER_FAIL = "UPDATE_DRIVER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const RESET_DRIVER = "RESET_DRIVER";