
import './Header.css';
import logo from '../../assests/images/odds-logo.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify, faClose } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { emptyBusinessSession } from '../../store/actions/businessActions/businessUsers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Header = () => {

    const location = useLocation();

    const { userDetail } = useSelector((state) => state.userDetails);

    const { types } = useSelector((state) => state.registerType);

    const dispatch = useDispatch();

    const menuLinks = [
        {
            title: "Benefits",
            url: "/#features",
        },
        {
            title: "Integrations",
            url: "/integrations",
        },
        // {
        //     title: "Savings",
        //     url: "/savings",
        // },
    ]

    const handleLogout = () => {
        dispatch(emptyBusinessSession());
    }

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
              element.style.scrollMarginTop = '50px';
              element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    })

    return(
        <Navbar expand="xl" className="header-main py-5px px-2.5 sticky-top w-full">
            <div className='flex flex-row items-center w-full mx-auto max-w-screen-1xl 4xl:max-w-screen-2xl'>
                <div className='w-3/5 md:w-1/3 xl:w-29 px-2.5 py-2.5 header-logo'>
                    <Navbar.Brand href='/' className='p-0 m-0'>
                        <img src={logo} alt='ODDS Logo' className='w-full max-w-300' />
                    </Navbar.Brand>
                </div>
                <div className='flex-1 w-full text-right'>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <FontAwesomeIcon icon={faAlignJustify} className="bars-icon" />
                        <FontAwesomeIcon icon={faClose} className="close-icon" />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto py-2.5 flex-wrap justify-end">
                            {menuLinks.map((item, i) => (
                                <Nav.Link href={item.url} className="mr-3 py-3.5 px-20px text-black leading-none h-fit" key={i}>{item.title}</Nav.Link>
                            ))}

                            <Nav.Link href="/request-demo" className="mr-3 py-3.5 px-20px rounded-3xl btn-primary-blue leading-none h-fit">Business Request Demo</Nav.Link>

                            {userDetail && userDetail.userId ? 

                                <NavDropdown title={`${userDetail.userName}`} id="collapsible-nav-dropdown2" className='dropdown-header btn-text'>
                                    {types && types.length > 0 ?
                                        (userDetail.customerGroup === types.filter(item => item.name === 'consumer')[0].customer_group_id ?

                                            <NavDropdown.Item href="/consumerdashboard" className="py-14px px-20px">Dashboard</NavDropdown.Item>

                                        : userDetail.customerGroup === types.filter(item => item.name === 'sales_person')[0].customer_group_id ?

                                            <NavDropdown.Item href="/saleperson" className="py-14px px-20px">Dashboard</NavDropdown.Item>

                                        : userDetail.customerGroup === types.filter(item => item.name === 'driver')[0].customer_group_id ?

                                            <NavDropdown.Item href="/driverdashboard" className="py-14px px-20px">Dashboard</NavDropdown.Item>

                                        :
                                            <NavDropdown.Item href="/dashboard" className="py-14px px-20px">Dashboard</NavDropdown.Item>)

                                    :
                                        null
                                    }
                                    
                                    <NavDropdown.Item onClick={handleLogout} className="py-14px px-20px">Logout</NavDropdown.Item>
                                </NavDropdown>
                            :
                                <>

                                <NavDropdown title="Get Started" id="collapsible-nav-dropdown" className='dropdown-header btn-primary-red'>
                                    <NavDropdown.Item href="/register" className="py-14px px-20px">Business Account</NavDropdown.Item>
                                    <NavDropdown.Item href="/consumerregister" className="py-14px px-20px">Consumer Account</NavDropdown.Item>
                                </NavDropdown>

                                <NavDropdown title="Login" id="collapsible-nav-dropdown2" className='dropdown-header btn-text'>
                                    <NavDropdown.Item href="/login" className="py-14px px-20px">Business Login</NavDropdown.Item>
                                    <NavDropdown.Item href="/consumerlogin" className="py-14px px-20px">Consumer Login</NavDropdown.Item>
                                </NavDropdown>

                                </>
                            }

                        </Nav>
                    </Navbar.Collapse>
                </div>
            </div>
        </Navbar>
    )
}

export default Header