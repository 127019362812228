import axios from "axios"
import { REFERRAL_EMAIL_ERROR, REFERRAL_EMAIL_REQUEST, REFERRAL_EMAIL_SUCCESS, RENT_FREE_EMAIL_ERROR, RENT_FREE_EMAIL_REQUEST, RENT_FREE_EMAIL_SUCCESS } from "../types/formEmailsTypes"

// Referral Form Email
export const sendReferralEmail = (fields) => async (dispatch) => {
    try {

        dispatch({ type: REFERRAL_EMAIL_REQUEST });
        
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/sendreferral`,
            fields,
            {headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            }}
        )

        dispatch({
            type: REFERRAL_EMAIL_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: REFERRAL_EMAIL_ERROR,
            payload: error?.response? error.response.data : 'Network Error',
        })
    }
}

// Rent Free Email
export const sendRentFreeEmail = (fields) => async (dispatch) => {
    try {

        dispatch({ type: RENT_FREE_EMAIL_REQUEST });

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/sendrentfree`,
            fields,
            {headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            }}
        )

        dispatch({
            type: RENT_FREE_EMAIL_SUCCESS,
            payload: data,
        })
        
    } catch (error) {
        dispatch({
            type: RENT_FREE_EMAIL_ERROR,
            payload: error?.response? error.response.data : 'Network Error',
        })
    }
}