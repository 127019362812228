import gallery1 from '../../../assests/images/about/gallery/gallery1.jpg';
import gallery2 from '../../../assests/images/about/gallery/gallery2.jpg';
import gallery3 from '../../../assests/images/about/gallery/gallery3.jpg';
import gallery4 from '../../../assests/images/about/gallery/gallery4.jpg';
import gallery5 from '../../../assests/images/about/gallery/gallery5.jpg';
import gallery6 from '../../../assests/images/about/gallery/gallery6.jpg';
import gallery7 from '../../../assests/images/about/gallery/gallery7.jpg';
import gallery8 from '../../../assests/images/about/gallery/gallery8.jpg';
import { useState } from "react";
import './GallerySection.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Lightbox from "yet-another-react-lightbox";

const GallerySection = () => {

    const [index, setIndex] = useState(-1);

    const gallery = [
        {
            src: gallery1,
            title: "Gallery1",
        },
        {
            src: gallery2,
            title: "Gallery2",
        },
        {
            src: gallery3,
            title: "Gallery3",
        },
        {
            src: gallery4,
            title: "Gallery4",
        },
        {
            src: gallery5,
            title: "Gallery5",
        },
        {
            src: gallery6,
            title: "Gallery6",
        },
        {
            src: gallery7,
            title: "Gallery7",
        },
        {
            src: gallery8,
            title: "Gallery8",
        }
    ]

    return(
        <section className="about-gallery-section bg-[#f2f4fc]">
            <div className="max-w-screen-1xl mx-auto py-50 px-2.5">
                <h2 className='text-primary-text md:text-[35px] text-center mb-25px'>Our Office</h2>
                    <div className="grid grid-cols-1 md:grid-cols-4 pt-15px grid-flow-row gap-[30px] p-2.5">
                        {gallery.map((item, i) => (
                            <div className='relative overflow-hidden rounded-[20px] gallery-src h-full' key={i}>
                                <img
                                    src={item.src}
                                    alt={item.title}
                                    className='rounded-[20px] w-full h-full object-cover cursor-pointer'
                                    onClick={() => setIndex(i)}
                                />
                            </div>
                        ))}
                        <Lightbox
                            index={index}
                            slides={gallery}
                            open={index >= 0}
                            close={() => setIndex(-1)}
                        />
                    </div>
            </div>
        </section>
    )
}

export default GallerySection