import axios from "axios";
import { CLEAR_ERRORS, DRIVER_USER_DETAIL_FAIL, DRIVER_USER_DETAIL_REQUEST, DRIVER_USER_DETAIL_SUCCESS, LOGIN_DRIVER_FAIL, LOGIN_DRIVER_REQUEST, LOGIN_DRIVER_SUCCESS, REGISTER_DRIVER_ERROR, REGISTER_DRIVER_REQUEST, REGISTER_DRIVER_SUCCESS, UPDATE_DRIVER_REQUEST, UPDATE_DRIVER_SUCCESS, UPDATE_DRIVER_FAIL } from "../../types/driverTypes/driverUsers"

// Register Driver
export const registerDriverUser = (driverData) => async (dispatch) => {
    try {
        
        dispatch({ type: REGISTER_DRIVER_REQUEST });

        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                "Authorization": `Bearer ${process.env.REACT_APP_TOKEN}`,
            }
        }

        const { data} = await axios.post(
            `${process.env.REACT_APP_API_URL}/createdrivers`,
            driverData,
            config
        )

        dispatch({
            type: REGISTER_DRIVER_SUCCESS,
            payload: data.result,
        });

    } catch (error) {
        dispatch({
            type: REGISTER_DRIVER_ERROR,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
}

// Login Driver
export const loginDriverUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_DRIVER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${process.env.REACT_APP_TOKEN}`,
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/businesslogin`,
            userData,
            config
        );

        dispatch({
            type: LOGIN_DRIVER_SUCCESS,
            payload: data.user,
        })
        
    } catch (error) {
        dispatch({
            type: LOGIN_DRIVER_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        })
    }
} 

// driver details
export const detailDriverUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: DRIVER_USER_DETAIL_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${process.env.REACT_APP_TOKEN}`,
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/driverdetails`,
            userData,
            config
        );

        dispatch({
            type: DRIVER_USER_DETAIL_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type: DRIVER_USER_DETAIL_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        })
    }
}

// Update Driver User
export const updateDriverProfile = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_DRIVER_REQUEST });

        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/updatedriver`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_DRIVER_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_DRIVER_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Clear all errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}