import { REFERRAL_EMAIL_ERROR, REFERRAL_EMAIL_REQUEST, REFERRAL_EMAIL_SUCCESS, RENT_FREE_EMAIL_ERROR, RENT_FREE_EMAIL_REQUEST, RENT_FREE_EMAIL_SUCCESS } from "../types/formEmailsTypes";

export const sendReferralEmailReducer = (state = {types: {}}, { type, payload }) => {
    switch (type) {
        case REFERRAL_EMAIL_REQUEST:
            return {
                loading: true,
            }
        case REFERRAL_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: payload,
            }
        case REFERRAL_EMAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        default:
            return state;
    }
}

// Rent Free Email
export const sendRentFreeEmailReducer = (state = {types: {}}, { type, payload }) => {
    switch (type) {
        case RENT_FREE_EMAIL_REQUEST:
            return {
                loading: true,
            }
        case RENT_FREE_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: payload,
            }
        case RENT_FREE_EMAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        default:
            return state;
    }
}