import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { getStatesNames } from "../../../store/actions/stateNamesAction";
import { usePlacesWidget } from "react-google-autocomplete";
import { updateDriverProfile, clearErrors, detailDriverUser } from '../../../store/actions/driverActions/driverUsers';
import { UPDATE_DRIVER_RESET } from "../../../store/types/driverTypes/driverUsers";
import { useSnackbar } from "notistack";
import defaultUpload from '../../../assests/images/driver/default-cart.png';

function DriverForm(){

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { statesList } = useSelector((state) => state.statesNames);
    const { driverUserDetail, loading } = useSelector((state) => state.driverUserDetails);
    const { error, isUpdated } = useSelector((state) => state.driverProfile);

    const [objectId, setObjectId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [driverAddress, setDriverAddress] = useState('');
    const [driverStreetAddress, setDriverStreetAddress] = useState('');
    const [driverCity, setDriverCity] = useState('');
    const [driverState, setDriverState] = useState('');
    const [driverZipCode, setDriverZipCode] = useState('');
    const [driverCountry, setDriverCountry] = useState('');
    const [driverLat, setDriverLat] = useState('');
    const [driverLong, setDriverLong] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [paypalEmail, setPaypalEmail] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [licenseNumber, setLicenseNumber] = useState('');
    const [licenseExpiration, setLicenseExpiration] = useState('');
    const [accNumber, setAccNumber] = useState('');
    const [accNumberVerify, setAccNumberVerify] = useState('');
    const [abaNumber, setAbaNumber] = useState('');
    const [abaNumberVerify, setAbaNumberVerify] = useState('');
    const [ssNumber, setSsNumber] = useState('');
    const [driverDob, setDriverDob] = useState('');
    const [licenseImg, setLicenseImg] = useState('');
    const [licensePreview, setLicensePreview] = useState('');
    const [insauranceImg, setInsauranceImg] = useState('');
    const [insaurancePreview, setInsaurancePreview] = useState('');
    const [voidedImg, setVoidedImg] = useState('');
    const [voidedPreview, setVoidedPreview] = useState('');
    const [selfieImg, setSelfieImg] = useState('');
    const [selfiePreview, setselfiePreview] = useState('');
    const [onDemand, setOnDemand] = useState();

    // Handle Phone Change
    const handlePhoneChange = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setPhone(value);
    }

    // Handle Date Change
    const handleDateChange = (e) => {
        setDriverDob(e.target.value);
        if(e.target.value){
            e.target.classList.add('hasvalue');
        } else {
            e.target.classList.remove('hasvalue');
        }
    }

    // set license image
    const handleLicenceImg = (e) => {
        setLicenseImg(e.target.files[0]);
    }

    // set insaurance image
    const handleInsauranceImg = (e) => {
        setInsauranceImg(e.target.files[0]);
    }

    // set voided check image
    const handleVoidedImg = (e) => {
        setVoidedImg(e.target.files[0]);
    }

    // set selfie image
    const handleSelfieImg = (e) => {
        setSelfieImg(e.target.files[0]);
    }

    // Address Autocomplete
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if(place){
                const addressComponents = place.address_components;

                // Extract address components
                const streetAddress = addressComponents.find(component =>
                    component.types.includes('route')
                )?.long_name || '';

                setDriverStreetAddress(streetAddress);

                const postalCode = addressComponents.find(component =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                setDriverZipCode(postalCode);

                const city = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setDriverCity(city);

                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setDriverState(state);

                const country = addressComponents.find(component =>
                    component.types.includes('country')
                )?.short_name || '';

                setDriverCountry(country);

                const lat = place.geometry.location.lat() || '';
                const long = place.geometry.location.lng() || '';

                setDriverLat(lat);
                setDriverLong(long);
            }
            setDriverAddress(place.formatted_address);
        },
        options: {
            types: ["address"],
            componentRestrictions: { country: "us" },
        },
    })

    // Submit form
    const handleSubmit = (e) => {
        e.preventDefault();

        if(accNumber !== ''){
            if(accNumber !== accNumberVerify){
                enqueueSnackbar("Account Number does't match", { variant: "error" });
                return;
            }
        }

        if(abaNumber !== ''){
            if(abaNumber !== abaNumberVerify){
                enqueueSnackbar("ABA Number does't match", { variant: "error" });
                return;
            }
        }

        const formData = new FormData();
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("address", driverAddress);
        formData.set("street_address", driverStreetAddress);
        formData.set("city", driverCity);
        formData.set("state", driverState);
        formData.set("zipcode", driverZipCode);
        formData.set("country", driverCountry);
        formData.set("driver_lat", driverLat);
        formData.set("driver_long", driverLong);
        formData.set("phone", phone);
        formData.set("email", email);
        formData.set("paypal_email", paypalEmail);
        formData.set("vehicle_type", vehicleType);
        formData.set("license_number", licenseNumber);
        formData.set("license_expiration", licenseExpiration);
        formData.set("acc_number", accNumber);
        formData.set("aba_number", abaNumber);
        formData.set("ss_number", ssNumber);
        formData.set("driver_dob", driverDob);
        formData.set("license", licenseImg);
        formData.set("insaurance", insauranceImg);
        formData.set("voided_check", voidedImg);
        formData.set("current_selfie", selfieImg);
        formData.set("ondemand", onDemand);

        dispatch(updateDriverProfile(formData));
    }

    useEffect(() => {

        if(loading === false && driverUserDetail && driverUserDetail.status === 1){
            setObjectId(driverUserDetail.user._id);
            setCustomerId(driverUserDetail.user.customer_id);
            setFirstName(driverUserDetail.user.firstname);
            setLastName(driverUserDetail.user.lastname);
            setEmail(driverUserDetail.user.email);
            setPhone(driverUserDetail.user.telephone);
            setDriverAddress(driverUserDetail.driverData.address);
            setDriverStreetAddress(driverUserDetail.driverData.street_address);
            setDriverCity(driverUserDetail.driverData.city);
            setDriverState(driverUserDetail.driverData.state);
            setDriverZipCode(driverUserDetail.driverData.zipcode);
            setDriverCountry(driverUserDetail.driverData.country);
            setDriverLat(driverUserDetail.driverData.driver_lat);
            setDriverLong(driverUserDetail.driverData.driver_long);
            setPaypalEmail(driverUserDetail.driverData.paypal_email);
            setVehicleType(driverUserDetail.driverData.vehicle_type);
            setLicenseNumber(driverUserDetail.driverData.license_number);
            setLicenseExpiration(driverUserDetail.driverData.license_expiration);
            setAccNumber(driverUserDetail.driverData.acc_number);
            setAccNumberVerify(driverUserDetail.driverData.acc_number);
            setAbaNumber(driverUserDetail.driverData.aba_number);
            setAbaNumberVerify(driverUserDetail.driverData.aba_number);
            setSsNumber(driverUserDetail.driverData.ss_number);
            setDriverDob(driverUserDetail.driverData.driver_dob);
            setLicensePreview(driverUserDetail.driverData.driving_license);
            setInsaurancePreview(driverUserDetail.driverData.insaurance);
            setVoidedPreview(driverUserDetail.driverData.voided_check);
            setselfiePreview(driverUserDetail.driverData.current_selfie);
            setOnDemand(driverUserDetail.driverData.ondemand);
        }

        if (error) {
            enqueueSnackbar(error ? error : "Network Error!", { variant: "error" });
            dispatch(clearErrors());
        }
        
        // Profile updated
        if (isUpdated) {

            enqueueSnackbar("Profile Updated Successfully.", { variant: "success" });

            var userDetails = {
                obj_id: objectId,
                email: email,
                customer_id: customerId,
            }
            dispatch(detailDriverUser(userDetails))
            dispatch({ type: UPDATE_DRIVER_RESET });
        }

        dispatch(getStatesNames());

    }, [dispatch, driverUserDetail, loading, isUpdated, error, objectId, email, customerId, enqueueSnackbar]);

    return(
        <Form className="form my-50" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="flex flex-col md:flex-row gap-[15px] mb-15px md:mb-20px">
                <Form.Group as={Col} controlId="firstname">
                    <Form.Control
                        type="text"
                        name="firstname"
                        placeholder="First Name*"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="lastname">
                    <Form.Control
                        type="text"
                        name="lastname"
                        placeholder="Last Name*"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="driver_address">
                    <Form.Control
                        ref={ref}
                        type="text"
                        name="driver_address"
                        placeholder="Address*"
                        defaultValue={driverAddress}
                        required
                    />
                </Form.Group>
            </div>

            <div className="flex flex-col md:flex-row gap-[15px] mb-15px md:mb-20px">
                <Form.Group as={Col} controlId="city">
                    <Form.Control
                        type="text"
                        name="city"
                        placeholder="City*"
                        value={driverCity}
                        onChange={(e) => setDriverCity(e.target.value)}
                        required
                    />
                </Form.Group>
                            
                <Form.Group as={Col} controlId="driver_state">
                    <Form.Select
                        name="driver_state"
                        className="form-control"
                        value={driverState}
                        onChange={(e) => setDriverState(e.target.value)}
                        required
                    >
                        <option value={""}>Select State</option>
                        {statesList && statesList.length >=1 && statesList.map((state, i) => (
                            <option key={i} value={state.state_name} id={state.state_code}>{state.state_name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="zip_code">
                    <Form.Control
                        type="text"
                        name="zip_code"
                        placeholder="Zip Code*"
                        value={driverZipCode}
                        onChange={(e) => setDriverZipCode(e.target.value)}
                        required
                    />
                </Form.Group>
            </div>

            <div className="flex flex-col md:flex-row gap-[15px] mb-15px md:mb-20px">
                <Form.Group as={Col} controlId="phone_number">
                    <Form.Control
                        type="text"
                        name="phone_number"
                        placeholder="Phone*"
                        required
                        minLength={10}
                        maxLength={10}
                        value={phone}
                        onChange={(e) => handlePhoneChange(e)}
                    />
                </Form.Group>
                            
                <Form.Group as={Col} controlId="email_address">
                    <Form.Control
                        type="email"
                        name="email_address"
                        placeholder="Email*"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="paypal_email">
                    <Form.Control
                        type="email"
                        name="paypal_email"
                        placeholder="Paypal Email"
                        value={paypalEmail}
                        onChange={(e) => setPaypalEmail(e.target.value)}
                    />
                </Form.Group>
            </div>

            <div className="flex flex-col md:flex-row gap-[30px] pt-20px mb-15px md:mb-20px justify-center">
                <Form.Group className="flex gap-[15px]" controlId="fulldriver">
                    <Form.Label><b>Full Time Driver</b></Form.Label>
                    <Form.Check type="radio" id="fulldriver" name="drivers" value="0" checked={onDemand === 0} onChange={() => setOnDemand(0)} />
                </Form.Group>

                <Form.Group className="flex gap-[15px]" controlId="ondemand">
                    <Form.Label><b>On Demand Driver</b></Form.Label>
                    <Form.Check type="radio" id="ondemand" name="drivers" value="1" checked={onDemand === 1} onChange={() => setOnDemand(1)} />
                </Form.Group>
            </div>

            <div className="flex flex-col md:flex-row gap-[15px] pt-20px mb-15px md:mb-20px">
                <Form.Group as={Col} controlId="vehicle_type">
                    <Form.Select
                        name="vehicle_type"
                        className="form-control"
                        value={vehicleType}
                        onChange={(e) => setVehicleType(e.target.value)}
                    >
                        <option value={""}>Select Vehicle Type</option>
                        <option value={"car"}>Car</option>
                        <option value={"scooter"}>Scooter</option>
                        <option value={"bicycle"}>Bicycle</option>
                        <option value={"motorcycle"}>Motorcycle</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="license_number">
                    <Form.Control
                        type="text"
                        name="license_number"
                        placeholder="Driver's License Number"
                        value={licenseNumber}
                        onChange={(e) => setLicenseNumber(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="license_expiration">
                    <Form.Control
                        type="text"
                        name="license_expiration"
                        placeholder="Driver License Expiration"
                        value={licenseExpiration}
                        onChange={(e) => setLicenseExpiration(e.target.value)}
                    />
                </Form.Group>
            </div>

            <div className="flex flex-col md:flex-row gap-[15px] mb-15px md:mb-20px">
                <Form.Group as={Col} controlId="acc_number">
                    <Form.Control
                        type="text"
                        name="acc_number"
                        placeholder="Bank Account Number For Payroll"
                        value={accNumber}
                        onChange={(e) => setAccNumber(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="acc_number_verify">
                    <Form.Control
                        type="text"
                        name="acc_number_verify"
                        placeholder="Bank Account Number Verify"
                        value={accNumberVerify}
                        onChange={(e) => setAccNumberVerify(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="aba_number">
                    <Form.Control
                        type="text"
                        name="aba_number"
                        placeholder="Bank ABA Number"
                        value={abaNumber}
                        onChange={(e) => setAbaNumber(e.target.value)}
                    />
                </Form.Group>
            </div>

            <div className="flex flex-col md:flex-row gap-[15px] mb-15px md:mb-20px">
                <Form.Group as={Col} controlId="aba_number_verify">
                    <Form.Control
                        type="text"
                        name="aba_number_verify"
                        placeholder="Bank ABA Number Verify"
                        value={abaNumberVerify}
                        onChange={(e) => setAbaNumberVerify(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="ss_number">
                    <Form.Control
                        type="text"
                        name="ss_number"
                        placeholder="Social Security Number"
                        value={ssNumber}
                        onChange={(e) => setSsNumber(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="driver_dob">
                    <Form.Control
                        type="date"
                        name="driver_dob"
                        data-placeholder="Birth Date"
                        className={`${driverDob ? 'hasvalue' : ''}`}
                        value={driverDob}
                        onChange={(e) => handleDateChange(e)}
                    />
                </Form.Group>
            </div>

            <div className="flex flex-wrap gap-[20px] pt-20px mb-15px md:mb-20px justify-center align-items-center">
                <div className="image-upload-block">
                    <img src={insaurancePreview ? `${process.env.REACT_APP_API_URL}/images/${insaurancePreview}` : defaultUpload} alt="Insaurance" className="image-upload" />
                    <label htmlFor="insaurance">
                        <span className="form-label">Upload Insurance Card: </span>
                        <input 
                            type="file"
                            className="form-file-input" 
                            name="insaurance" 
                            id="insaurance"
                            onChange={(e) => handleInsauranceImg(e)}
                        />
                    </label>
                </div>
                
                <div className="image-upload-block">
                    <img src={licensePreview ? `${process.env.REACT_APP_API_URL}/images/${licensePreview}` : defaultUpload} alt="License" className="image-upload" />
                    <label htmlFor="license">
                        <span className="form-label">Upload Drivers License: </span>
                        <input 
                            type="file" 
                            className="form-file-input" 
                            name="license" 
                            id="license"
                            onChange={(e) => handleLicenceImg(e)}
                         />
                    </label>
                </div>

                <div className="image-upload-block">
                    <img src={voidedPreview ? `${process.env.REACT_APP_API_URL}/images/${voidedPreview}` : defaultUpload} alt="Voided Check" className="image-upload" />
                    <label htmlFor="voidedcheck">
                        <span className="form-label">Upload Voided Check: </span>
                        <input 
                            type="file" 
                            className="form-file-input" 
                            name="voidedcheck" 
                            id="voidedcheck"
                            onChange={(e) => handleVoidedImg(e)}
                         />
                    </label>
                </div>

                <div className="image-upload-block">
                    <img src={selfiePreview ? `${process.env.REACT_APP_API_URL}/images/${selfiePreview}` : defaultUpload} alt="Selfie" className="image-upload" />
                    <label htmlFor="selfie">
                        <span className="form-label">Upload Current Selfie: </span>
                        <input 
                            type="file" 
                            className="form-file-input" 
                            name="selfie" 
                            id="selfie"
                            onChange={(e) => handleSelfieImg(e)}
                         />
                    </label>
                </div>
            </div>

            <Form.Group className="py-20px text-center">
                <Button type="submit" className="form-btn-submit btn-submit" id="driver_update_btn">Update Your Profile</Button>
            </Form.Group>
        </Form>
    )
}

export default DriverForm