import { useEffect, useState } from "react"
import MetaData from "../../../layout/MetaData"
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { registerType } from "../../../store/actions/registerTypesAction";
import { useNavigate } from "react-router-dom";
import { salesForgotPassword } from "../../../store/actions/salesActions/salesUsers";
import { useSnackbar } from "notistack";

function ForgotPassword(){

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const { types } = useSelector((state) => state.registerType);
    const { userDetail } = useSelector((state) => state.userDetails);
    const { loading, success, error } = useSelector((state) => state.forgotPassword);

    const [emailAddress, setEmailAddress] = useState('');

    // handle submit form
    const handleSubmit = (e) => {
        e.preventDefault();

        var group = types && types.filter(item => item.name === 'sales_person');
        var customer_group_id = group && group[0].customer_group_id;

        const formData = new FormData();
        formData.set('email', emailAddress);
        formData.set('customer_group_id', customer_group_id);

        dispatch(salesForgotPassword(formData));

    }

    useEffect(() => {

        if(userDetail && userDetail.userId){
            navigate('/saleperson');
        }

        if(error){
            enqueueSnackbar(error, { variant: "error" });
        }

        if(success){
            enqueueSnackbar("Reset password email has been sent to your e-mail address.", { variant: "success" });
            setEmailAddress('');
        }

        dispatch(registerType());

    }, [dispatch, userDetail, navigate, enqueueSnackbar, error, success]);

    return(
        <>
            <MetaData
                title={"Forgot Your Password"}
            />

            {/* Forgot password */}
            <section className="sales-login sales-pages px-20px py-60px">
                <div className="max-w-screen-750 mx-auto p-6 sm:p-10 flex flex-col bg-primary-bgForms rounded-[20px]">
                    <h2 className="text-center mb-0 pb-10">Forgot Password</h2>

                    <Form method="post" className="sales-login-form sales-form" onSubmit={handleSubmit}>
                        <Form.Group controlId="email_address" className="mb-10 relative">
                            <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
                            <Form.Control
                                type="email"
                                name="email_address"
                                required
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.value)}
                                placeholder="Enter Email Address"
                            />
                        </Form.Group>

                        <Form.Group className="text-center">
                            <Button 
                                type="submit" 
                                className={`btn-submit mt-10 ${loading ? 'disabled' : ''}`}
                            >
                                    {loading ? 'Processing...' : 'Submit'}
                            </Button>
                        </Form.Group>

                        <p className="get-started text-center text-lg mt-25px">Back To <a href="/loginsales" className="no-underline">Login</a></p>
                    </Form>
                </div>
            </section>
        </>
    )
}

export default ForgotPassword