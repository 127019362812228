import { Button, Form } from "react-bootstrap"
import MetaData from "../../../layout/MetaData"
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { registerType } from "../../../store/actions/registerTypesAction";
import { salesResetPassword } from "../../../store/actions/salesActions/salesUsers";

function ResetPassword(){

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const { types } = useSelector((state) => state.registerType);
    const { userDetail } = useSelector((state) => state.userDetails);
    const { loading, success, error } = useSelector((state) => state.forgotPassword);

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // handle submit form 
    const handleSubmit = (e) => {
        e.preventDefault();

        if(password !== confirmPassword){
            enqueueSnackbar("Password not match!", { variant: "error" });
            return;
        }

        var group = types && types.filter(item => item.name === 'sales_person');
        var customer_group_id = group && group[0].customer_group_id;

        const formData = new FormData();
        formData.set('userid', userId);
        formData.set('password', password);
        formData.set('customer_group_id', customer_group_id);

        dispatch(salesResetPassword(formData));

    }

    useEffect(() => {

        if(userDetail && userDetail.userId){
            navigate('/saleperson');
        }

        const fetchUserId = () => {
            const pathParts = location.pathname.split('/');
            return pathParts[pathParts.length - 1];
        }

        const userid = fetchUserId();
        if(location.pathname === `/reset-password/${userid}`){
            setUserId(userid);
        } else {
            enqueueSnackbar("Invalid Token", { variant: "error" });
            setTimeout(() => {
                navigate('/loginsales');
            }, 5000);
        }

        if(error){
            enqueueSnackbar(error, { variant: "error" });
        }

        if(success){
            enqueueSnackbar("Password Updated Successfully!", { variant: "success" });
            setPassword('');
            setConfirmPassword('');
            setTimeout(() => {
                navigate('/loginsales');
            }, 5000);
        }

        dispatch(registerType());

    },[dispatch, location, enqueueSnackbar, navigate, userDetail, error, success]);

    return(
        <>
            <MetaData
                title={"Reset Your Password"}
            />

            {/* Forgot password */}
            <section className="sales-login sales-pages px-20px py-60px">
                <div className="max-w-screen-750 mx-auto p-6 sm:p-10 flex flex-col bg-primary-bgForms rounded-[20px]">
                    <h2 className="text-center mb-0 pb-10">Reset Password</h2>
                    <Form className="sales-login-form sales-form" method="post" onSubmit={handleSubmit}>

                        <Form.Group className="mb-10 relative" controlId="password">
                            <FontAwesomeIcon icon={faLock} className="input-icon" />
                            <Form.Control 
                                type="password" 
                                placeholder="Enter New Password" 
                                name="password" 
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="new-password"
                                minLength={8}
                                maxLength={15}
                            />
                        </Form.Group>

                        <Form.Group className="mb-10 relative" controlId="confirm_password">
                            <FontAwesomeIcon icon={faLock} className="input-icon" />
                            <Form.Control
                                type="password"
                                placeholder="Confirm New Password"
                                name="confirm_password"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                autoComplete="new-password"
                                minLength={8}
                                maxLength={15}
                            />
                        </Form.Group>

                        <Form.Group className="text-center">
                            <Button 
                                type="submit" 
                                className={`btn-submit mt-10 ${loading ? 'disabled' : ''}`}
                            >
                                {loading ? 'Processing...' : 'Submit'}
                            </Button>
                        </Form.Group>

                        <p className="get-started text-center text-lg mt-25px">Back To <a href="/loginsales" className="no-underline">Login</a></p>

                    </Form>
                </div>
            </section>
        </>
    )
}

export default ResetPassword