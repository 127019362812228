import MetaData from "../../../layout/MetaData";
import { Button, Form } from "react-bootstrap";
import './DriverRegisteration.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { registerType } from "../../../store/actions/registerTypesAction";

// Driver Apps images
import app1 from '../../../assests/images/driver/driver-app1.png';
import app2 from '../../../assests/images/driver/driver-app2.png';
import app3 from '../../../assests/images/driver/driver-app3.png';
import app4 from '../../../assests/images/driver/driver-app4.png';

import shape5 from '../../../assests/images/refer/shape5.png';
import shape2 from '../../../assests/images/about/shape2.png';
import shape1 from '../../../assests/images/business/shape1.png';

import { clearErrors, registerDriverUser } from "../../../store/actions/driverActions/driverUsers";
import { useSnackbar } from "notistack";
import { setBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import { RESET_DRIVER } from "../../../store/types/driverTypes/driverUsers";
import { useRef } from "react";
import { getStatesNames } from "../../../store/actions/stateNamesAction";
import { usePlacesWidget } from "react-google-autocomplete";


const DriverRegisteration = () => {

    // Driver App images
    const appsImages = [
        {
            src: app1,
            alt: "App1",
        },
        {
            src: app2,
            alt: "App2",
        },
        {
            src: app3,
            alt: "App3",
        },
        {
            src: app4,
            alt: "App4",
        }
    ]

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const licenseRef = useRef();
    const insauranceRef = useRef();

    const { types } = useSelector((state) => state.registerType);
    const { statesList } = useSelector((state) => state.statesNames);
    const { userDetail } = useSelector((state) => state.userDetails);
    const { isAuthenticated, error, user } = useSelector((state) => state.driverUser);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [driverAddress, setDriverAddress] = useState('');
    const [driverCity, setDriverCity] = useState('');
    const [driverZipCode, setDriverZipCode] = useState('');
    const [driverCountry, setDriverCountry] = useState('');
    const [driverState, setDriverState] = useState('');
    const [driverStreetAddress, setDriverStreetAddress] = useState('');
    const [driverLat, setDriverLat] = useState('');
    const [driverLong, setDriverLong] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [experience, setExperience] = useState('');
    const [license, setLicense] = useState('');
    const [insaurance, setInsaurance] = useState('');
    // const recaptcha = useRef();

    // set license image
    const handleLicenceImg = (e) => {
        setLicense(e.target.files[0]);
    }

    // set insaurance image
    const handleInsauranceImg = (e) => {
        setInsaurance(e.target.files[0]);
    }

    // handle phone change
    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPhone(value);
    }

    // Address Autocomplete
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if(place){
                const addressComponents = place.address_components;

                // Extract address components
                const streetAddress = addressComponents.find(component => 
                    component.types.includes('route')
                )?.long_name || '';

                setDriverStreetAddress(streetAddress);

                const postalCode = addressComponents.find(component => 
                    component.types.includes('postal_code')
                )?.long_name || '';
                
                setDriverZipCode(postalCode);

                const city = addressComponents.find(component => 
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setDriverCity(city);

                const state = addressComponents.find(component => 
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setDriverState(state);

                const country = addressComponents.find(component =>
                    component.types.includes('country')
                )?.short_name || '';

                setDriverCountry(country);

                const lat = place.geometry?.location.lat() || '';
                const long = place.geometry?.location.lng() || '';

                setDriverLat(lat);
                setDriverLong(long);
            }
            setDriverAddress(place.formatted_address);
        },
        options: {
            types: ["address"],
            componentRestrictions: { country: "us" },
        },
    });

    // handle driver submit
    const handleSubmit = (e) => {
        e.preventDefault();

        var group = types && types.filter(item => item.name === 'driver');
        var customer_group_id = group && group[0].customer_group_id;

        // const captchaValue = recaptcha.current.getValue();
        // if(!captchaValue){
        //     alert('Please verify the reCAPTCHA!');
        //     return;
        // }

        const formData = new FormData();
        formData.set("customer_group_id", customer_group_id);
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("address", driverAddress);
        formData.set("city", driverCity);
        formData.set("zipcode", driverZipCode);
        formData.set("country", driverCountry);
        formData.set("state", driverState);
        formData.set("street_address", driverStreetAddress);
        formData.set("driver_lat", driverLat);
        formData.set("driver_long", driverLong);
        formData.set("phone", phone);
        formData.set("email", email);
        formData.set("password", password);
        formData.set("experience", experience);
        formData.set("license", license);
        formData.set("insaurance", insaurance);

        dispatch(registerDriverUser(formData));

    }

    useEffect(() => {
        if(error){
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
            licenseRef.current.value = "";
            setLicense('');
            insauranceRef.current.value = "";
            setInsaurance('');
        }

        if(isAuthenticated){
            var userDetails = {
                userId: user._id,
                userEmail: user.email,
                customerGroup: user.customer_group_id,
                customerId: user.customer_id,
                userName: user.firstname,
            }

            dispatch(setBusinessSession(userDetails));

            dispatch({ type: RESET_DRIVER });
        }

        if(userDetail && userDetail.userId){
            navigate('/driverdashboard');
        }

        dispatch(registerType());

        dispatch(getStatesNames());

    }, [dispatch, userDetail, navigate, enqueueSnackbar, isAuthenticated, error, user])

    return(
        <>
            <MetaData
                title={"Driver Registration"}
            />

            {/* Register Section */}
            <section className="driver-form-section relative py-50 md:py-100px">
                <img src={shape1} alt="Shape1" className="block max-w-[125px] absolute top-[50px] right-[8%] -z-10" />

                <div className="max-w-screen-1xl mx-auto pb-15px md:pb-50 flex flex-col relative">
                    <div className="px-2.5">
                        <h1 className="text-5xl md:text-[58px] font-semibold text-center mb-9 text-primary-text">Driver Registration</h1>
                    </div>
                </div>

                <div className="max-w-screen-1xl mx-auto flex flex-col md:flex-row relative">
                    <div className="w-full md:w-[50%] p-2.5">
                        <div className="driver-form lg:p-25px">
                            <Form className="form" method="post" onSubmit={handleSubmit} encType="multipart/form-data">
                                <Form.Group controlId="firstname" className="mb-15px">
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        placeholder="First Name*"
                                        required
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="lastname" className="mb-15px">
                                    <Form.Control
                                        type="text"
                                        name="lastname"
                                        placeholder="Last Name*"
                                        required
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="driver_address" className="mb-15px">
                                    <Form.Control
                                        ref={ref}
                                        type="text"
                                        name="driver_address"
                                        placeholder="Address*"
                                        required
                                        defaultValue={driverAddress}
                                    />
                                </Form.Group>

                                <Form.Group controlId="city" className="mb-15px">
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="City*"
                                        required
                                        value={driverCity}
                                        onChange={(e) => setDriverCity(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="zip_code" className="mb-15px">
                                    <Form.Control
                                        type="text"
                                        name="zip_code"
                                        placeholder="Zip Code*"
                                        required
                                        value={driverZipCode}
                                        onChange={(e) => setDriverZipCode(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Select 
                                    name="driver_state" 
                                    id="driver_state" 
                                    className="mb-15px form-control"
                                    value={driverState}
                                    onChange={(e) => setDriverState(e.target.value)}
                                    required
                                >
                                    <option value={""}>Select State</option>
                                    {statesList && statesList.length >=1 && statesList.map((state, i) => (
                                        <option key={i} value={state.state_name} id={state.state_code}>{state.state_name}</option>
                                    ))}
                                </Form.Select>

                                <Form.Group controlId="phone_number" className="mb-15px">
                                    <Form.Control
                                        type="text"
                                        name="phone_number"
                                        placeholder="Phone*"
                                        required
                                        value={phone}
                                        onChange={(e) => handlePhoneChange(e)}
                                        minLength={10}
                                        maxLength={10}
                                    />
                                </Form.Group>

                                <Form.Group controlId="email_address" className="mb-15px">
                                    <Form.Control
                                        type="email"
                                        name="email_address"
                                        placeholder="Email*"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="password" className="mb-15px">
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder="Password*"
                                        required
                                        minLength={8}
                                        maxLength={15}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="experience" className="mb-15px">
                                    <Form.Control 
                                        as="textarea"
                                        rows={6}
                                        name="experience"
                                        placeholder="Experience: (what other companies have you worked for)"
                                        value={experience}
                                        onChange={(e) => setExperience(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group controlId="license" className="mb-15px">
                                    <Form.Label className="text-lg">Drivers License <span className="text-danger required">*</span></Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="license"
                                        required
                                        onChange={(e) => handleLicenceImg(e)}
                                        ref={licenseRef}
                                    />
                                </Form.Group>

                                <Form.Group controlId="insaurance" className="mb-15px">
                                    <Form.Label className="text-lg">Proof of insurance <span className="text-danger required">*</span></Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="insaurance"
                                        required
                                        onChange={(e) => handleInsauranceImg(e)}
                                        ref={insauranceRef}
                                    />
                                </Form.Group>

                                {/* <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} /> */}

                                <Form.Group className="py-15px mb-15px">
                                    <Button type="submit" className="form-btn-submit btn-submit" id="btn-driver-submit">Submit To ODDS Driving Registration Dept</Button>
                                </Form.Group>

                                <p className="font-semibold get-started text-xl mt-20px">Already Register? <a href="/drivers-login" className="text-primary-blue btn-stated">Login</a></p>
                            </Form>
                        </div>
                    </div>
                    <div className="w-full md:w-[50%] p-2.5">
                        <div className="grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-[30px]">
                            {appsImages.map((item, index) => (
                                <img
                                    key={index}
                                    src={item.src}
                                    alt={item.alt}
                                    className="object-contain m-auto"
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <img src={shape5} alt="Shape5" className="block max-w-[85px] absolute -z-10 top-[40%] left-[5%]" />
                <img src={shape2} alt="Shape2" className="block max-w-[125px] absolute z-10 -bottom-[45px] right-[4%]" />
            </section>
        </>
    )
}

export default DriverRegisteration