import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ContactContent from './ContactContent';
import { useDispatch, useSelector } from 'react-redux';
import { emptyBusinessSession } from '../../../../store/actions/businessActions/businessUsers';
import { useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import ProfilePayment from './ProfilePayment';
import TripContent from './TripContent';
import OddsOrders from './OddsOrders';
import { useTab } from '../TabContext';
import MoreOrders from './Moreorders';
import { TRIPS_ACTIVE_RESET } from '../../../../store/types/businessTypes/tripsRequested';

const FooterButtons = () => {

    const dispatch = useDispatch();

    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);

    const { activeKey, setActiveKey } = useTab();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isTripsPassword, setIsTripsPassword] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [tripsPassword, setTripsPassword] = useState('');
    const [tripsPasswordError, setTripsPasswordError] = useState('');

    const handlePasswordClose = () => setPasswordShow(false);

    // handle active tab
    const handleActiveTab = (k) => {
        setActiveKey(k);
        dispatch({ type: TRIPS_ACTIVE_RESET })
    }

    const handleLogout = () => {
        dispatch(emptyBusinessSession());
    }

    // handle trips click
    const handleTripsClick = () => {
        if(loading === false && businessUserDetail && businessUserDetail.status === 1){
            if(businessUserDetail.businessdata.trips_password && businessUserDetail.businessdata.trips_password !== ''){
                setIsTripsPassword(true);
                setPasswordShow(true);
            } else {
                setIsTripsPassword(false);
                setPasswordShow(false);
            }
        }
    }

    // handle password submit
    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        
        if(businessUserDetail && businessUserDetail.businessdata.trips_password === tripsPassword){
            setIsTripsPassword(false);
            handlePasswordClose();
            setTripsPasswordError('');
            setTripsPassword('');
        } else {
            setTripsPasswordError('Incorrect Password!');
            setTimeout(() => {
                setTripsPasswordError('');
            }, 3000);
        }
    }

    return(
        <>
            <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => handleActiveTab(k)}>
                <div className='flex flex-col w-full mt-20px'>
                    <Tab.Content>
                        <Tab.Pane eventKey="home"></Tab.Pane>
                        <Tab.Pane eventKey="profile"><ProfilePayment /></Tab.Pane>
                        <Tab.Pane eventKey="trips">
                            {isTripsPassword ?
                                <Modal 
                                    show={passwordShow} 
                                    onHide={handlePasswordClose}
                                    centered={true} 
                                    className="popup-map-modal" 
                                    backdropClassName="dashboard-backdrop"
                                    backdrop="static"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Please Enter Security Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form onSubmit={handlePasswordSubmit} method='post' className='form form_trips_password py-4'>
                                            <Form.Group controlId='trips_password' className='mb-15px'>
                                                <Form.Control 
                                                    type='password'
                                                    name='trips_password'
                                                    required
                                                    placeholder='Enter Password'
                                                    value={tripsPassword}
                                                    onChange={(e) => setTripsPassword(e.target.value)}
                                                    autoComplete='new-password'
                                                />
                                                {tripsPasswordError &&
                                                    <p className='text-danger'>{tripsPasswordError}</p>
                                                }
                                            </Form.Group>
                                            <Form.Group className='text-center'>
                                                <Button type='submit' className='submit_trips_password'>Submit</Button>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                            :
                                <TripContent />
                            }
                        </Tab.Pane>
                        <Tab.Pane eventKey="orders"><OddsOrders /></Tab.Pane>
                        <Tab.Pane eventKey="contact"><ContactContent /></Tab.Pane>
                        <Tab.Pane eventKey="moreOrders"><MoreOrders /></Tab.Pane>
                    </Tab.Content>
                
                    <Nav variant="pills" className="flex-row justify-around footer-btn-links mt-20px">
                        <Nav.Item>
                            <Nav.Link eventKey="home">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="profile">My Profile / Payment</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="trips" onClick={handleTripsClick}>Trips Requested</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="orders">ODDS Orders</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={handleShow}>Tutorial</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="contact" className='green-btn'>Contact Us</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </Tab.Container>

            <Modal show={show} onHide={handleClose} centered={true} className="popup-dashboard-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton></Modal.Header>
                
                <Modal.Body className="relative">
                    <iframe width="" height="700" src="https://www.youtube.com/embed/oNNKrJBj9nk" title="ODDS Product Tutorial" frameBorder="0" className="runT" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" style={{width: '100%'}}></iframe>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default FooterButtons