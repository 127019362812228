import MetaData from "../../layout/MetaData"
import referImg from '../../assests/images/refer/refer.png';
import { Button, Col, Form } from "react-bootstrap";
import { useState } from "react";
import shape2 from '../../assests/images/refer/shape2b.png';
import shape5 from '../../assests/images/refer/shape5.png';
import { useDispatch, useSelector } from "react-redux";
import { sendReferralEmail } from "../../store/actions/formEmailAction";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

const Refer = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { loading, success, error } = useSelector((state) => state.referralEmail);

    const [businessName, setBusinessName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [referBusinessName, setReferBusinessName] = useState('');
    const [referFirstName, setReferFirstName] = useState('');
    const [referLastName, setReferLastName] = useState('');
    const [referContactNumber, setReferContactNumber] = useState('');
    const [referCountry, setReferCountry] = useState('USA');

    // handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set("referrer_business", businessName);
        formData.set("referrer_firstname", firstName);
        formData.set("referrer_lastname", lastName);
        formData.set("referrer_email", email);
        formData.set("referrer_phone", contactNumber);
        formData.set("referral_business", referBusinessName);
        formData.set("referral_firstname", referFirstName);
        formData.set("referral_lastname", referLastName);
        formData.set("referral_country", referCountry);
        formData.set("referral_phone", referContactNumber);

        dispatch(sendReferralEmail(formData));
    }

    // handle form reset
    const handleFormReset = () => {
        setBusinessName('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setContactNumber('');
        setReferBusinessName('');
        setReferFirstName('');
        setReferLastName('');
        setReferCountry('');
        setReferContactNumber('');
    }

    // handle contact number change
    const handleContactNumber = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setContactNumber(value);
    }

    // handle refer contact change
    const handleReferContact = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setReferContactNumber(value);
    }

    useEffect(() => {
        if(error){
            enqueueSnackbar(error, { variant: "error" });
        }

        if(success){
            enqueueSnackbar("Email send successfully!", { variant: "success" });
            handleFormReset();
        }
    },[enqueueSnackbar, error, success]);

    return(
        <>
            <MetaData
                title={"Refer A Restaurant – ODDS – On Demand Delivery Solution"}
            />

            <section className="business-refer-restaurant relative overflow-hidden">
                <div className="max-w-screen-1xl mx-auto pt-50 md:pt-20 pb-6 md:pb-50 flex flex-col text-center text-primary-text relative">
                    <div className="w-full p-2.5">
                        <h1 className="text-5xl md:text-[56px] font-bold mb-20px">How our  Referral Program works</h1>
                        <h5 className="leading-relaxed text-22">Spread the word about ODDS and earn $1000 per successful referral! Help restaurants simplify their deliveries, increase their revenue and cut food aggregator marketing fees.</h5>
                    </div>

                    <img src={shape2} alt="Shape2" className="hidden md:block max-w-[69px] absolute top-9 -left-8 -z-10 filter-color-purple" />
                </div>
            </section>

            <section className="refer-form-section relative overflow-hidden pb-50">
                <div className="max-w-screen-1xl mx-auto flex flex-col md:flex-row relative">
                    <div className="w-full md:w-[45%] p-2.5">
                        <div className="refer-form p-25px">
                            <Form className="form pb-15px" action="post" onSubmit={handleSubmit}>
                                <h4 className="text-primary-blue mb-15px py-2.5 font-semibold">Your details</h4>

                                <Form.Group className="mb-15px" controlId="business_name">
                                    <Form.Control type="text" name="business_name" placeholder="Business Name" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                                </Form.Group>

                                <div className="flex flex-col lg:flex-row gap-2.5 mb-15px">
                                    <Form.Group as={Col} controlId="firstname">
                                        <Form.Control type="text" name="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} required placeholder="First Name"/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="last_name">
                                    <Form.Control type="text" name="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} required placeholder="Last Name"/>
                                    </Form.Group>
                                </div>

                                <div className="flex flex-col lg:flex-row gap-2.5 mb-15px">
                                    <Form.Group as={Col} controlId="email_address">
                                        <Form.Control type="text" name="email_address" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email"/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="contact_number">
                                    <Form.Control type="text" name="contact_number" value={contactNumber} onChange={(e) => handleContactNumber(e)} required placeholder="Contact Number" minLength={10} maxLength={10}/>
                                    </Form.Group>
                                </div>

                                <h4 className="text-primary-blue mt-50 pb-2.5 mb-15px font-semibold">Who are you referring</h4>

                                <Form.Group className="mb-15px" controlId="refer_business_name">
                                    <Form.Control type="text" name="refer_business_name" placeholder="Business Name" value={referBusinessName} onChange={(e) => setReferBusinessName(e.target.value)} required />
                                </Form.Group>

                                <div className="flex flex-col lg:flex-row gap-2.5 mb-15px">
                                    <Form.Group as={Col} controlId="refer_first_name">
                                        <Form.Control type="text" name="refer_first_name" value={referFirstName} onChange={(e) => setReferFirstName(e.target.value)} placeholder="First Name" required />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="refer_last_name">
                                    <Form.Control type="text" name="refer_last_name" value={referLastName} onChange={(e) => setReferLastName(e.target.value)} placeholder="Last Name" required/>
                                    </Form.Group>
                                </div>

                                <Form.Select name="refer_country" id="refer_country" className="mb-15px form-control" value={referCountry} onChange={(e) => setReferCountry(e.target.value)}>
                                    <option value="USA">USA</option>
                                    <option value="Canada">Canada</option>
                                </Form.Select>

                                <Form.Group className="mb-15px" controlId="refer_contact_number">
                                    <Form.Control type="text" name="refer_contact_number" placeholder="Contact Number" value={referContactNumber} onChange={(e) => handleReferContact(e)} required minLength={10} maxLength={10} />
                                </Form.Group>

                                <Form.Group className="py-15px mb-15px">
                                    <Button type="submit" className={`form-btn-submit btn-submit ${loading ? 'disabled' : ''}`}>
                                        {loading ? 'Processing...' : 'Submit'}
                                    </Button>
                                </Form.Group>

                            </Form>
                        </div>
                    </div>
                    <div className="w-full md:w-[55%] p-2.5">
                        <img src={referImg} alt="Refer" className="w-full mb-20px" />
                        <p className="mb-2.5 text-center px-50 text-primary-text33 leading-relaxed">Successful Referral that converts any aggregator to self-delivery or averages more than 10 internal deliveries per day on a monthly average. Process takes 3-5 days.</p>
                    </div>

                    <img src={shape2} alt="Shape2" className="hidden md:block max-w-[63px] absolute bottom-[18%] -right-8 -z-10 filter-color-green" />
                </div>
                <img src={shape5} alt="Shape5" className="hidden md:block max-w-[130px] absolute top-1/4 left-[4%] -z-10 filter-color" />
            </section>

        </>
    )
}

export default Refer