import axios from "axios";
import { CLEAR_ERRORS, LOGIN_BUSINESS_FAIL, LOGIN_BUSINESS_REQUEST, LOGIN_BUSINESS_SUCCESS, REGISTER_BUSINESS_FAIL, REGISTER_BUSINESS_REQUEST, REGISTER_BUSINESS_SUCCESS, USER_BUSINESS_ENDSESSION, USER_BUSINESS_SESSION, BUSINESS_USER_DETAIL_REQUEST, BUSINESS_USER_DETAIL_SUCCESS, BUSINESS_USER_DETAIL_FAIL, UPDATE_BUSINESS_REQUEST, UPDATE_BUSINESS_SUCCESS, UPDATE_BUSINESS_FAIL, UPDATE_BUSINESS_ACH_REQUEST, UPDATE_BUSINESS_ACH_SUCCESS, UPDATE_BUSINESS_ACH_FAIL, UPDATE_BUSINESS_CC_REQUEST, UPDATE_BUSINESS_CC_SUCCESS, UPDATE_BUSINESS_CC_FAIL, UPDATE_BUSINESS_DATA_REQUEST, UPDATE_BUSINESS_DATA_SUCCESS, UPDATE_BUSINESS_DATA_FAIL, PAY_ACH_REQUEST, PAY_ACH_SUCCESS, PAY_ACH_FAIL } from "../../types/businessTypes/businessUsers";
import { TRIPS_FILTER_RESET } from "../../types/businessTypes/tripsRequested";

// Register User
export const registerBusinessUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: REGISTER_BUSINESS_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/createcustomer`,
            userData,
            config
        );

        dispatch({
            type: REGISTER_BUSINESS_SUCCESS,
            payload: data.result,
        });

    } catch (error) {
        dispatch({
            type: REGISTER_BUSINESS_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Clear All Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};


// Login User
export const loginBusinessUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_BUSINESS_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/businesslogin`,
            userData,
            config
        );

        dispatch({
            type: LOGIN_BUSINESS_SUCCESS,
            payload: data.user,
        });
        

    } catch (error) {
        dispatch({
            type: LOGIN_BUSINESS_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// User Details
export const detailBusinessUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: BUSINESS_USER_DETAIL_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/customerdetails`,
            userData,
            config
        );

        dispatch({
            type: BUSINESS_USER_DETAIL_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: BUSINESS_USER_DETAIL_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Update Business User
export const updateBusinessProfile = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_BUSINESS_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/updatecustomersprofile`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_BUSINESS_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_BUSINESS_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Update Business User ACH
export const updateBusinessProfileAch = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_BUSINESS_ACH_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/updatecustomersach`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_BUSINESS_ACH_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_BUSINESS_ACH_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Pay Business ACH Payment
export const payBusinessACH = (id) => async (dispatch) => {
    try {

        dispatch({ type: PAY_ACH_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/paynoteregistercustomer/${id}`,
            config
        );

        dispatch({
            type: PAY_ACH_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: PAY_ACH_FAIL,
            payload: error?.response?.data? error.response.data.msg : 'Network Error',
        });
    }
};

// Update Business User Credit Card
export const updateBusinessProfileCC = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_BUSINESS_CC_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/updatecustomerscc`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_BUSINESS_CC_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_BUSINESS_CC_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Update Business User Radius
export const updateBusinessRadius = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_BUSINESS_DATA_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/updateBusinessdataByAdmin`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_BUSINESS_DATA_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_BUSINESS_DATA_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Session user start
export const setBusinessSession = (user) => (dispatch, getState) => {
    dispatch({ 
        type: USER_BUSINESS_SESSION,
        payload: user,
    });

    localStorage.setItem('userDetail', JSON.stringify(getState().userDetails.userDetail));
}

// Session user end
export const emptyBusinessSession = () => async (dispatch, getState) => {

    dispatch({ type: USER_BUSINESS_ENDSESSION });
    dispatch({ type: TRIPS_FILTER_RESET });

    localStorage.setItem('userDetail', JSON.stringify(getState().userDetails.userDetail));
    localStorage.clear();
}

// Login User From Admin
export const loginBusinessUserFromAdmin = (userData) => async (dispatch) => {
    try {

        let postdata = JSON.stringify({
            "_id": userData
        });

        dispatch({ type: LOGIN_BUSINESS_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
           `${process.env.REACT_APP_API_URL}/businessLoginFromAdmin`,          
            postdata,
            config
        );

        dispatch({
            type: LOGIN_BUSINESS_SUCCESS,
            payload: data.user,
        });
        

    } catch (error) {
        dispatch({
            type: LOGIN_BUSINESS_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};

// Auto Login From Admin
export const autoLoginUserFromAdmin = (userData) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_BUSINESS_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/salesloginfromadmin`,
            userData,
            config
        );

        dispatch({
            type: LOGIN_BUSINESS_SUCCESS,
            payload: data.user,
        });
        

    } catch (error) {
        dispatch({
            type: LOGIN_BUSINESS_FAIL,
            payload: error?.response?.data? error.response.data.message : 'Network Error',
        });
    }
};