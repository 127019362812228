import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_SALES_RESET } from "../../../../store/types/salesTypes/salesUsers";
import { clearErrors, detailSalesUser, updateSalesProfile } from "../../../../store/actions/salesActions/salesUsers";
import { setBusinessSession } from "../../../../store/actions/businessActions/businessUsers";

const ProfileForm = () =>{

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { salesUserDetail, loading } = useSelector((state) => state.salesUserDetails);
    const { error, isUpdated } = useSelector((state) => state.salesProfile);

    const [objectId, setObjectId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [groupId, setGroupId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');

    const handleProfileSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("email", email);
        formData.set("telephone", telephone);

        dispatch(updateSalesProfile(formData))
    }

    // handle telephone change
    const handleTelephone = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setTelephone(value);
    }

    useEffect(() => {
        if(loading === false && salesUserDetail && salesUserDetail.status === 1){
            setObjectId(salesUserDetail.user._id)
            setCustomerId(salesUserDetail.user.customer_id);
            setFirstName(salesUserDetail.user.firstname);
            setLastName(salesUserDetail.user.lastname);
            setEmail(salesUserDetail.user.email);
            setTelephone(salesUserDetail.user.telephone);
            setGroupId(salesUserDetail.user.customer_group_id);
        }

        // Profile Error
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        

    }, [dispatch, loading, salesUserDetail, error, enqueueSnackbar])

    useEffect(()=>{
        // Profile updated
        if (isUpdated) {

            enqueueSnackbar("Profile Updated Successfully.", { variant: "success" });

            var userDetails = {
                obj_id: objectId,
                email: email,
                customer_id: customerId,
            }
            dispatch(detailSalesUser(userDetails))
            dispatch({ type: UPDATE_SALES_RESET });

            var sessionDetails = {
                userId: objectId,
                userEmail: email,
                customerGroup: groupId,
                customerId: customerId,
                userName: firstName,
            }
            dispatch(setBusinessSession(sessionDetails))
        }
    })

    return(
        <Form className="sales-profile-form business_profile_form" method="post" onSubmit={handleProfileSubmit}>
            <div className="flex flex-col lg:flex-row gap-[20px] mb-20px">
                <Form.Group as={Col} controlId="firstname">
                    <Form.Control type="text" placeholder="First Name" name="firstname" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>

                <Form.Group as={Col} controlId="lastname">
                    <Form.Control type="text" placeholder="Last Name" name="lastname" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>
            </div>

            <Form.Group className="mb-20px" controlId="email_address">
                <Form.Control type="email" placeholder="Email" name="email_address" required value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-20px" controlId="telephone">
                <Form.Control type="tel" placeholder="Phone Number" name="telephone" required minLength={9} maxLength={10} pattern="[0-9]*" value={telephone} onChange={(e) => handleTelephone(e)} />
            </Form.Group>

            <Form.Group className="text-left">
                <Button type="submit" className="btn-profile-submit">Update My Details</Button>
            </Form.Group>

        </Form>
    )
}

export default ProfileForm