import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { clearOrderErrors, gteOrderInvoice, myInternalOrders, sendDriverOrders } from "../../../../store/actions/businessActions/businessOrders";
import monitizing from '../../../../assests/images/business/monitoring.gif';
import adminMonitizing from '../../../../assests/images/business/pre-loader-31.gif';
import { DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import axios from "axios";
import OrderInvoice from "./OrderInvoice";
import { auth, db, get, set, push, ref } from "../../../../Firebase";
import { signInWithCustomToken } from 'firebase/auth';
import moment from "moment/moment";
import { io } from "socket.io-client";
import homeIcon from '../../../../assests/images/home-icon.png';
import restaurantIcon from '../../../../assests/images/restaurant.png';
import driverIcon from '../../../../assests/images/car.png';
import { updateBusinessRadius } from "../../../../store/actions/businessActions/businessUsers";
import { UPDATE_BUSINESS_DATA_RESET } from "../../../../store/types/businessTypes/businessUsers";

// Countdown Timer
const CountdownTimer = ({ initialTime, itemId, isCountdown, setIsCountdown }) => {
    // Parse the initialTime prop into minutes and seconds
    const parseTimeToSeconds = (timeStr) => {

        if(timeStr.split(':').map(Number).length === 3){

            const [hours, minutes, seconds] = timeStr.split(':').map(Number);
            return hours * 60 * 60 + minutes * 60 + seconds;

        } else if(timeStr.split(':').map(Number).length === 2){
            const [minutes, seconds] = timeStr.split(':').map(Number);
            return minutes * 60 + seconds;
        } else {
            const [seconds] = timeStr.split(':').map(Number);
            return seconds;
        }
    };
  
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const secs = seconds % 60

        if(hours > 0){
            return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${secs < 10 ? `0${secs}` : secs}`;
        } else {
            return `${minutes < 10 ? `0${minutes}` : minutes}:${secs < 10 ? `0${secs}` : secs}`;
        }
        
    };
  
    const [timeLeft, setTimeLeft] = useState(parseTimeToSeconds(initialTime));
  
    useEffect(() => {
        if (timeLeft <= 0){ 
            // setDisplayCountdown(false)
            setIsCountdown(isCountdown.filter(item => item !== itemId));
            return
        };
  
      const intervalId = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
      
      return () => clearInterval(intervalId);
      
    }, [timeLeft, itemId, setIsCountdown, isCountdown]);
  
    return (
      <span>{formatTime(timeLeft)}</span>
    );
};

const StatSection = () => {

    const dispatch = useDispatch();
    const allOrdersRefs = {}
    const { enqueueSnackbar } = useSnackbar();
    const { orders, error } = useSelector((state) => state.myInternalOrders);

    const { userDetail } = useSelector((state) => state.userDetails);
    const { businessUserDetail } = useSelector((state) => state.businessUserDetails);
    const { sendDriver, error: sendDriverError } = useSelector((state) => state.sendDrivers);
    const { isRadiusUpdated, errorRadius } = useSelector((state) => state.businessProfile);

    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [businessId, setBusinessId] = useState('');

    const [show, setShow] = useState(false);
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [mapDistance, setMapDistance] = useState("");
    const [mapTime, setMapTime] = useState("");
    const [mapLat, setMapLat] = useState("");
    const [mapLong, setMapLong] = useState("");
    // const [displayCountdown, setDisplayCountdown] = useState(true);
    const [orderId, setOrderId] = useState("");
    const [orderType, setOrderType] = useState("");
    const [displayInvoice, setDisplayInvoice] = useState(false);

    const [isCountdown, setIsCountdown] = useState([]);

    const [showDriver, setShowDriver] = useState(false);
    const [driverLat, setDriverLat] = useState(null);
    const [driverLng, setDriverLng] = useState(null);
    const [deliverLng, setDeliverLng] = useState(null);
    const [deliverLat, setDeliverLat] = useState(null);
    const [pickupLng, setPickupLng] = useState(null);
    const [pickupLat, setPickupLat] = useState(null);

    const [driverSound, setDriverSound] = useState(0);
    const [driverSoundChange, setDriverSoundChange] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDriverClose = () => setShowDriver(false);
    const handleDriverShow = () => setShowDriver(true);

    const [userToken, setUserToken] = useState(null);

    // handle map show
    const handleMapShow = (distance, time, lat, long, delAdd, pickAdd) => {
        setMapDistance(distance);
        setMapTime(time);
        setMapLat(lat);
        setMapLong(long);

        const distanceService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            distanceService.route(
                {
                    origin: pickAdd,
                    destination: delAdd,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                if (status === 'OK') {
                   
                    setDirectionsResponse(response);
                    directionsRenderer.setDirections(response);

                    handleShow()

                } else {
                    alert('Error fetching distance:', status);
                }
                }
            ); 
    }

    // handle driver track map show
    const handleDriverMap = (driLat, driLng, delLat, delLng, pickLat, pickLng) => {
        setDriverLat(parseFloat(driLat));
        setDriverLng(parseFloat(driLng));
        setDeliverLat(parseFloat(delLat));
        setDeliverLng(parseFloat(delLng));
        setPickupLat(parseFloat(pickLat));
        setPickupLng(parseFloat(pickLng));
        handleDriverShow()
    }

    // handle contact message click
    const handleMessgeCLick = (id) => {
        if(allOrdersRefs[id].style.display === "table-row"){
            allOrdersRefs[id].style.display = "";
            document.getElementById(id+"_ordermsg").innerHTML = '';
        } else {

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/getordersms/${id}`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
            })
            .then(function (response) {
                if(response.data){
                    var messageData = '';
                    response.data.map((item,i)=>(
                        messageData += '<div class="w-1/4 orders_messages_div"><p class="mb-0 font-semibold">Admin Notification</p><p class="mb-0">'+item.text+'</p><p class="mb-0">SMS TO '+item.contactPhone+'</p><p class="mb-0">'+(new Date(item.timestamp).toLocaleString("en-US"))+'</p></div>'
                    ))

                    document.getElementById(id+"_ordermsg").innerHTML = '<td colSpan="6"><div class="flex py-1.5 px-3.5 gap-[15px]">'+messageData+'</div></td>';

                    allOrdersRefs[id].style.display = "table-row";
                }
            })
            .catch(function (response) {
                console.log(response);
                document.getElementById(id+"_ordermsg").innerHTML = '';
            });
        }
    }

    // handle get invoice
    const getInvoice = (id, type) => {
        setOrderId(id);
        setOrderType(type);
        setDisplayInvoice(true);
        dispatch(gteOrderInvoice(id, type))
    }

    const generateUniqueCode = () => {
        return moment().unix();
    };

    // Add new message
    const addMessage = async (question, answer) => {
        const messagesRef = ref(db, `dashboardMessage/${userId}/message`);
        const messageRef = push(messagesRef);
        let indexKey;
        let exists = true;

        while (exists) {
            indexKey = generateUniqueCode();
            const snapshot = await get(messageRef);
            const existingMessages = snapshot.val();
            exists =
                existingMessages &&
                Object.values(existingMessages).some(
                // eslint-disable-next-line
                (msg) => msg.indexKey === indexKey
            );
        }

        const currentDateTime = new Date().toLocaleString("en-US", {
            timeZone: "America/New_York", // Set to the desired USA timezone
        });
      
        const newMessageRef = ref(db, `dashboardMessage/${userToken.uid}/${userId}/message/${indexKey}`);
          
        set(newMessageRef, {
            indexKey,
            adminname: userName,
            message: question,
            datetime: currentDateTime,
            msgtype: "Question",
        })
        .then(() => {
            console.log("Message added successfully");
        })
        .catch((error) => {
            console.error("Error adding message: ", error);
        });

        setTimeout(()=> {
            let indexKey= generateUniqueCode();
          
            const currentDateTime = new Date().toLocaleString("en-US", {
                timeZone: "America/New_York", // Set to the desired USA timezone
            });
        
            const newMessageRef = ref(db, `dashboardMessage/${userToken.uid}/${userId}/message/${indexKey}`);
            
            set(newMessageRef, {
                indexKey,
                adminname: "Odds Admin",
                message: answer,
                datetime: currentDateTime,
                msgtype: "Text",
            })
            .then(() => {
                console.log("Message added successfully");
            })
            .catch((error) => {
                console.error("Error adding message: ", error);
            });
        }, 8000)
        
    };

    // Function to get a custom token from the backend
    const getCustomToken = async () => {
        try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/custom-auth`, {
            userIdentifier: process.env.REACT_APP_FIREBASE_IDENTIFICATION,  // Pass whatever identifier you require
        });
        return response.data.token;
        } catch (error) {
        console.error('Error fetching custom token:', error);
        }
    };

    // Function to authenticate using the custom token
    const authenticateWithCustomToken = async () => {
        const token = await getCustomToken();
        if (token) {
            try {
                const userCredential = await signInWithCustomToken(auth, token);
                setUserToken(userCredential.user);
                console.log('Authenticated with custom token:', userCredential.user);
            } catch (error) {
                console.error('Error signing in with custom token:', error);
            }
        }
    };

    useEffect(() => {
        authenticateWithCustomToken();
        // eslint-disable-next-line
    }, []);

    // handle send drivers 
    const handleSendDrivers = (id) => {
        const formData = new FormData();
        formData.set("order_id", id);
        dispatch(sendDriverOrders(formData,id));
    }

    // handle sound change
    const handleDriverSoundChange = () => {
        setDriverSound(driverSound === 0 ? 1 : 0);
        setDriverSoundChange(true);
    }

    useEffect(() => {

        if(errorRadius){
            dispatch(clearOrderErrors());
        }

        if(isRadiusUpdated){
            dispatch({ type: UPDATE_BUSINESS_DATA_RESET });
            setDriverSoundChange(false);
        }

        if(driverSoundChange){
            setDriverSoundChange(false);
            const formData = {
                "_id": businessId,
                "order_arriving_sound": driverSound,
            }

            dispatch(updateBusinessRadius(formData))
        }
    }, [dispatch, driverSoundChange, driverSound, businessId, errorRadius, isRadiusUpdated])

    useEffect(() => {
        if(orders && orders.updatedResults && orders.updatedResults.length >= 1){
            setIsCountdown(orders.updatedResults.map(item => item.timediff > 0 ? item._id :'' ));
        }
    }, [orders])
   
    useEffect(() => {

        if(sendDriver && sendDriver.status === 1 ){
            dispatch(myInternalOrders(userDetail.userId));
        }

        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearOrderErrors());
        }

        if (sendDriverError) {
            enqueueSnackbar(sendDriverError, { variant: "error" });
            dispatch(clearOrderErrors());
        }

        if(businessUserDetail && businessUserDetail.status === 1 ){
            setUserName(businessUserDetail.businessdata.restaurant_name.substring(0, 23));
            setUserId(businessUserDetail.businessdata.customer_id);
            setDriverSound(businessUserDetail.businessdata.order_arriving_sound ? businessUserDetail.businessdata.order_arriving_sound : 0);
            setBusinessId(businessUserDetail.businessdata._id ? businessUserDetail.businessdata._id : '');
        }
        
    }, [dispatch, error, enqueueSnackbar, userDetail, businessUserDetail, sendDriver, sendDriverError]);

    useEffect(() => {
        if(userDetail && userDetail.userId){
            dispatch(myInternalOrders(userDetail.userId));
        }
        // eslint-disable-next-line
    }, [])

    // Socket
    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
            path: "/api/socket.io", // Match the server-side custom path
        });
    
        socket.on('drivernow-orderlist', (newOrders) => {
            console.log(newOrders);
            if(userDetail && userDetail.userId){
                dispatch(myInternalOrders(userDetail.userId));
            }
        })
    
    }, [dispatch, userDetail])

    return(
        <div className="stat-section">
            <div className="btn-section flex justify-center flex-wrap p-15px gap-2.5 mb-30px">
                <button>Today's Delivery {orders && orders.todayOrders}</button>
                <button>Yesterday {orders && orders.yesterdayOrders}</button>
                <button>Week {orders && orders.thisweekOrders}</button>
                <button className="hidden sm:block">Month {orders && orders.thismonthOrders}</button>
                <button className="hidden sm:block">Last Week {orders && orders.lastweekOrders}</button>
                <button className="hidden">Last 7 days 0</button>
                <button className="hidden sm:block">Last Month {orders && orders.lastmonthOrders}</button>
                <button 
                    className={`${driverSound === 1 ? 'btn-green' : 'btn-red'}`}
                    onClick={handleDriverSoundChange}
                >
                    {driverSound === 0 ? "Sound Off Driver Arriving" : "Sound On Driver Arriving"}
                </button>
            </div>

            <div id="gh_orders">
                {orders && orders.updatedResults && orders.updatedResults.length >= 1 && (
                    <Table responsive className="internal-orders-table">
                        <thead>
                            <tr>
                                <th className="w-[30%]">Order Information</th>
                                <th className="w-[10%]">Timing</th>
                                <th className="w-[20%]">Driver Location / Track</th>
                                <th className="w-[10%]">View Order / P</th>
                                <th className="w-[10%]">Est. Delivery</th>
                                <th className="w-[20%]">Admin Tracking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.updatedResults.map((item, i) => (
                                <Fragment key={i}>
                                <tr key={item._id+"_order"}>
                                    <td>
                                        <h5 className="mb-0">{item.delivery_firstname} {item.delivery_phone}</h5>
                                        <p className="mb-0 text-gray-500">
                                            {item.delivery_city === "" && item.delivery_state === "" ?
                                                item.delivery_address
                                            :
                                                item.delivery_city +', '+ item.delivery_state
                                            }
                                        </p>
                                        <p className="mb-0"><span type="button" className="text-black p-1 text-sm font-semibold" onClick={() => handleMessgeCLick(item._id)}>Contact Messages</span></p>
                                    </td>
                                    <td className="text-center">
                                        {
                                        item.timediff < 0 ?
                                            <img src={item.timer_img} alt="Order Type" className="w-20 h-20 mx-auto" />
                                        :
                                            isCountdown.includes(item._id) ?
                                                <div>
                                                    <p className="mb-0">
                                                        <CountdownTimer initialTime={item.timediff_value} itemId={item._id} setIsCountdown={setIsCountdown} isCountdown={isCountdown} /> 
                                                    </p>
                                                    <button type="button" className="text-blue-500" onClick={() => handleMapShow(item.order_mileage_distance, item.order_time_distance, item.delivery_lat, item.delivery_long, item.delivery_address, item.pickup_address )}>Map</button>
                                                </div>

                                            :
                                                <img src={item.timer_img} alt="Order Type" className="w-20 h-20 mx-auto" />
                                        }

                                    </td>
                                    <td className={`text-center driver_map_block ${item.order_status_id === 6 ? 'bgpurple' : ''}`}>
                                        {item.driver_lat && item.driver_lat !== '' && item.driver_lat !== '0' && item.driver_long && item.driver_long !== '' && item.driver_long !== '0' ?
                                            <>
                                                <span className="text-gray-500">Dropping Off</span>
                                                <span className="driver_name">{item.driver_name}</span>
                                                <span className="driver_map">Click <span className="text-blue-500" onClick={() => handleDriverMap(item.driver_lat, item.driver_long, item.delivery_lat, item.delivery_long, item.pickup_lat, item.pickup_long)}>Here</span> for Tracking</span>
                                            </>
                                        :
                                            item.driver_info
                                        }
                                    </td>
                                    <td>
                                        {item.order_type === 4 || item.order_type === 3 || item.order_type === 6 || item.order_type === 2 ?
                                            <img src={item.order_type_img} alt="Order Type" className="h-[120px] mx-auto cursor-pointer" onClick={()=> getInvoice(item._id, item.order_type)} />
                                        :
                                            <img src={item.order_type_img} alt="Order Type" className="h-[120px] mx-auto" />
                                        }
                                    </td>
                                    <td className="text-center">
                                        <img src={monitizing} alt="MONITORING" className="h-20 mx-auto" />
                                    </td>
                                    <td className="text-center system-moniter text-white">
                                        SYSTEM MONITORING DRIVER
                                        <img src={adminMonitizing} alt="SYSTEM MONITORING DRIVER" className="h-5 mx-auto" />
                                    </td>
                                </tr>

                                {/* Orders messages */}
                                <tr ref={ref => allOrdersRefs[item._id] = ref} className="orders-button-messages hidden" id={item._id+"_ordermsg"} key={item._id+"_ordermsg"}>
                                </tr>

                                {/* order buttons row */}
                                <tr className="orders-button-rows" key={item._id+"_orderbtn"}>
                                    <td colSpan={6}>
                                        <div className="flex">

                                            {item.custom_sms && item.custom_sms.map((sms, i) => (
                                                <div className="w-1/4 orders_sms_div" key={i} >
                                                    {item.order_status_id === 12 && sms.question === "SEND DRIVER" ?
                                                        <Button className="orders_sms green_btn send_driver_btn" onClick={() => handleSendDrivers(item._id)}>{sms.question}</Button>
                                                    :
                                                        <Button className="orders_sms" onClick={() => addMessage(sms.question, sms.response)}>{sms.question}</Button>
                                                    }
                                                </div>
                                            ))}
                                            
                                        </div>
                                    </td>
                                </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                )}
                
            </div>

            {/* Order Invoice */}
            {displayInvoice &&
                <OrderInvoice id={orderId} orderType={orderType} setDisplayInvoice={setDisplayInvoice} />
            }

            {/* Map Modal */}
            <Modal show={show} onHide={handleClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Satellite Distance <br/> {mapDistance} Miles {mapTime} Minutes <br/>Locked Wheel Movement</h4>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Latitude: {mapLat}<br/> Longitude: {mapLong}</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: 40.0583, lng: 74.4057}}
                        zoom={7}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeId: "satellite",
                        }}
                    >
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </Modal.Body>
            </Modal>


            {/* Driver Location Map Modal */}
            <Modal size="lg" show={showDriver} onHide={handleDriverClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-medium font-alata mb-0 p-2.5 text-center w-full">Map</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: driverLat, lng: driverLng}}
                        zoom={9}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: true,
                            mapTypeControl: true,
                            fullscreenControl: false,
                            streetViewControl: false,
                        }}
                    >
                        {deliverLat && deliverLng &&
                            <Marker icon={homeIcon} position={{lat: deliverLat, lng: deliverLng}} />
                        }

                        {driverLat && driverLng &&
                            <Marker icon={driverIcon} position={{lat: driverLat, lng: driverLng}} />
                        }

                        {pickupLat && pickupLng &&
                            <Marker icon={restaurantIcon} position={{lat: pickupLat, lng: pickupLng}} />
                        }
                        
                    </GoogleMap>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDriverClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StatSection