import axios from "axios";
import { GET_STATES_FAIL, GET_STATES_REQUEST, GET_STATES_SUCCESS } from "../types/stateNames";

// Get States Names
export const getStatesNames = () => async (dispatch) => {
    try {

        dispatch({ type: GET_STATES_REQUEST });

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getStatesName`,
            {headers: { 
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                'Content-Type': 'application/json'
            }}
        );
          
        dispatch({
            type: GET_STATES_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: GET_STATES_FAIL,
            payload: error?.response? error.response.data : 'Network Error',
        });
    }

};