import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jeanGabrial from '../../../assests/images/about/team/jean-gabriel.png';
import utkarsh from '../../../assests/images/about/team/utkarsh.png';
import evelyn from '../../../assests/images/about/team/evelyn.png';
import dali from '../../../assests/images/about/team/dali.png';
import bhupinder from '../../../assests/images/about/team/bhupinder.png';
import laurenSoto from '../../../assests/images/about/team/lauren-soto.png';
import erickaGuilbe from '../../../assests/images/about/team/ericka-guilbe.png';
import abhinav from '../../../assests/images/about/team/abhinav.png';
import tarun from '../../../assests/images/about/team/tarun.png';
import shikha from '../../../assests/images/about/team/shikha.png';
import richard from '../../../assests/images/about/team/richard.png';
import joseph from '../../../assests/images/about/team/joseph.png';
import roger from '../../../assests/images/about/team/roger.png';
import vinay from '../../../assests/images/about/team/vinay.png';

import { faLinkedin} from '@fortawesome/free-brands-svg-icons';
import './TeamSection.css';

const TeamSection = () => {

    const teamList = [
        {
            image: jeanGabrial,
            name: "Jean Gabriel",
            position: "Founder & CEO",
            linkedin: 'https://www.linkedin.com/in/jeanmarie-gabriel-b34295103',
        },
        {
            image: utkarsh,
            name: "Utkarsh Khare",
            position: "Chief Technology Officer",
            linkedin: 'https://www.linkedin.com/in/utkarshkhare/',
        },
        {
            image: evelyn,
            name: "Evelyn Garcia",
            position: "National Director of Operations",
        },
        {
            image: dali,
            name: "Dali Durdevic",
            position: "Head of Sales & Marketing",
            linkedin: 'https://www.linkedin.com/in/dali-durdevic-a5275556',
        },
        {
            image: bhupinder,
            name: "Bhupinder Singh",
            position: "Technical Lead (Web & API)",
        },
        {
            image: laurenSoto,
            name: "Lauren Soto",
            position: "East Cost Director of Driver Administration",
        },
        {
            image: erickaGuilbe,
            name: "Ericka Guilbe",
            position: "West Coast Director of Driver Administration",
        },
        {
            image: abhinav,
            name: "Abhinav Sinha",
            position: "AWS & Scripts)",
        },
        {
            image: tarun,
            name: "Tarun Chaudhary",
            position: "Sr. Software Developer (Web)",
        },
        {
            image: shikha,
            name: "Shikha Mahaldawan",
            position: "Project Coordinator & Quality Lead",
        },
        {
            image: richard,
            name: "Richard D. Harris",
            position: "Senior Legal Counsel - Day Pitney",
        },
        {
            image: joseph,
            name: "Joseph Doren",
            position: "Senior Accountant",
        },
        {
            image: roger,
            name: "Roger Gottilla",
            position: "Senior Legal Counsel - Wilson Elser",
        },
        {
            image: vinay,
            name: "Vinay Kumar",
            position: "Senior Consultant",
        }
    ]

    return(
        <section className="about-team-section">
            <div className="max-w-screen-1xl mx-auto py-50">
                <h2 className='text-primary-text md:text-[35px] text-center mb-25px'>Our Team</h2>
                <div className="flex flex-wrap justify-center">
                    {teamList.map((item,i) => (
                        <div className='w-full sm:w-2/4 992:w-1/5 px-15px mb-30px text-center text-primary-blue team-member-item' key={i}>
                            <img src={item.image} alt={item.name} className='size-40 mx-auto' />
                            <div className='pt-15px'>
                                <h3 className='mb-2.5 text-[22px]'>{item.name}</h3>
                                <p className='mb-2.5'>{item.position}</p>
                                {item.linkedin && (
                                    <a href={item.linkedin} className='size-[30px] team-social' target="_blank" rel="noreferrer">
                                        <FontAwesomeIcon icon={faLinkedin} className='text-xl'/>
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default TeamSection