import { Link } from "react-router-dom"

const Footer = () => {

    const productLinks = [
        // {
        //     title: "Saving Calculator",
        //     url: "/savings",
        // },
        {
            title: "Benefits",
            url: "/#features",
        },
        {
            title: "Integrations",
            url: "/integrations",
        },
        {
            title: "Integration Help",
            url: "/help",
        },
    ]

    const customerLinks = [
        {
            title: "Request Demo",
            url: "/request-demo",
        },
        {
            title: "Get Started",
            url: "/register",
        },
        {
            title: "Refer a Restaurant",
            url: "/refer",
        },
        {
            title: "Login",
            url: "/login",
        },
    ]

    const companyLinks = [
        {
            title: "About Us",
            url: "/about-us",
        },
    ]

    const salesLinks = [
        {
            title: "Sales Employment Opportunity",
            url: "/join-our-sales-team",
        },
        {
            title: "Sales Login",
            url: "/loginsales",
        },
        {
            title: "Sales Zoom Meeting",
            url: "/sales-meeting",
        },
        {
            title: "Sales Demo Request",
            url: "/request-demo",
        },
    ]

    const driversLinks = [
        {
            title: "Driver Registration",
            url: "/drivers-registration",
        },
    ]

    return(
        <>
            <footer className="bg-black text-white py-50 px-2.5">
                <div className="flex flex-col md:flex-row max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto">
                    
                    <div className="w-full md:w-1/5 p-2.5">
                        <h6 className="text-lg font-black mb-20px leading-none">Contact</h6>
                        <p>Sales & Support: <br/>
                        (833)-928-9464 <br/>
                        sales@oddsdrive.com <br/>
                        400 Valley Road Suite 202 <br/>
                        Mount Arlington, NJ 07856</p>
                    </div>

                    <div className="w-full md:w-1/5 p-2.5">
                        <h6 className="text-lg font-black mb-20px leading-none">Product</h6>
                        <ul className="list-none p-0 mb-2.5">
                            {productLinks.map((item,i) => (
                                <li key={i}><Link to={item.url} reloadDocument={true} className="text-white no-underline hover:underline">{item.title}</Link></li>
                            ))}
                        </ul>
                    </div>

                    <div className="w-full md:w-1/5 p-2.5">
                        <h6 className="text-lg font-black mb-20px leading-none">Customers</h6>
                        <ul className="list-none p-0 mb-2.5">
                            {customerLinks.map((item,i) => (
                                <li key={i}><Link to={item.url} reloadDocument={true} className="text-white no-underline hover:underline">{item.title}</Link></li>
                            ))}
                        </ul>
                    </div>
                    
                    <div className="w-full md:w-23 p-2.5">
                        <h6 className="text-lg font-black mb-20px leading-none">Company</h6>
                        <ul className="list-none p-0 mb-2.5">
                            {companyLinks.map((item,i) => (
                                <li key={i}><Link to={item.url} reloadDocument={true} className="text-white no-underline hover:underline">{item.title}</Link></li>
                            ))}
                        </ul>

                        <h6 className="text-lg font-black mb-0 pb-5px pt-2.5 leading-none">Sales</h6>
                        <ul className="list-none p-0">
                            {salesLinks.map((item,i) => (
                                <li key={i}><Link to={item.url} reloadDocument={true} className="text-white no-underline hover:underline">{item.title}</Link></li>
                            ))}
                        </ul>
                    </div>

                    <div className="w-full md:w-17 p-2.5">
                        <h6 className="text-lg font-black mb-20px leading-none">Drivers</h6>
                        <ul className="list-none p-0 mb-2.5">
                            {driversLinks.map((item,i) => (
                                <li key={i}><Link to={item.url} reloadDocument={true} className="text-white no-underline hover:underline">{item.title}</Link></li>
                            ))}
                        </ul>
                    </div>

                </div>
            </footer>
            <div className="footer-copyright bg-white px-5">
                <div className="max-w-screen-1xl mx-auto p-2.5">
                    <p className="mb-0 text-center">Copyright &copy; {new Date().getFullYear()} On Demand Delivery Solution. &nbsp;All rights reserved.</p>
                </div>
            </div>
        </>
    )
}

export default Footer