import { Fragment, useEffect, useState } from "react";
import { Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { clearOrderErrors, generateOrders, getDeliveredOrders, gteOrderInvoice } from "../../../../store/actions/businessActions/businessOrders";
import moment from "moment";
import './DeliveredOrders.css';
import axios from "axios";
import OrderInvoice from "../StatSection/OrderInvoice";
import { auth, db, get, set, push, ref } from "../../../../Firebase";
import { signInWithCustomToken } from 'firebase/auth';
import { useSnackbar } from "notistack";

const DeliveredOrders = () => {

    const dispatch = useDispatch();
    const enqueueSnackbar = useSnackbar();
    const allOrdersRefs = {}

    const { deliveredOrder } = useSelector((state) => state.deliveredOrders);
    const { businessUserDetail } = useSelector((state) => state.businessUserDetails);
    const { error } = useSelector((state) => state.generateOrders);

    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');

    const { userDetail } = useSelector((state) => state.userDetails);
    const [orderId, setOrderId] = useState("");
    const [orderType, setOrderType] = useState("");
    const [displayInvoice, setDisplayInvoice] = useState(false);

    const [userToken, setUserToken] = useState(null);

    // handle contact message click
    const handleMessgeCLick = (id) => {
        if(allOrdersRefs[id].style.display === "table-row"){
            allOrdersRefs[id].style.display = "";
            document.getElementById(id+"_ordermsg").innerHTML = '';
        } else {

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/getordersms/${id}`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
            })
            .then(function (response) {
                if(response.data){
                    var messageData = '';
                    response.data.map((item,i)=>(
                        messageData += '<div class="w-1/4 orders_messages_div"><p class="mb-0 font-semibold">Admin Notification</p><p class="mb-0">'+item.text+'</p><p class="mb-0">SMS TO '+item.contactPhone+'</p><p class="mb-0">'+(new Date(item.timestamp).toLocaleString("en-US"))+'</p></div>'
                    ))

                    document.getElementById(id+"_ordermsg").innerHTML = '<td colSpan="9"><div class="flex py-1.5 px-3.5 gap-[15px]">'+messageData+'</div></td>';

                    allOrdersRefs[id].style.display = "table-row";
                }
            })
            .catch(function (response) {
                console.log(response);
                document.getElementById(id+"_ordermsg").innerHTML = '';
            });
        }
    }

    // handle delivered time show
    const handleDeliveredTime = (id) => {
        if(document.getElementById(id+"_delTime").style.display === "block"){
            document.getElementById(id+"_delTime").style.display = "none";
        } else{
            document.getElementById(id+"_delTime").style.display = "block";
        }
    }

    const generateUniqueCode = () => {
        return moment().unix();
    };

    // handle chat message click
    const handleChatClick = async (question, response, order_id, generate) => {

        const messagesRef = ref(db, `dashboardMessage/${userToken.uid}/${userId}/message`);
        const messageRef = push(messagesRef);
        let indexKey;
        let exists = true;
        
        while (exists) {
            indexKey = generateUniqueCode();
            const snapshot = await get(messageRef);
            const existingMessages = snapshot.val();
            exists =
                existingMessages &&
                Object.values(existingMessages).some(
                // eslint-disable-next-line
                (msg) => msg.indexKey === indexKey
            );
        }
        
        const currentDateTime = new Date().toLocaleString("en-US", {
            timeZone: "America/New_York", // Set to the desired USA timezone
        });
              
        const newMessageRef = ref(db, `dashboardMessage/${userToken.uid}/${userId}/message/${indexKey}`);
                  
        set(newMessageRef, {
            indexKey,
            adminname: userName,
            message: question,
            datetime: currentDateTime,
            msgtype: "Question",
        })
        .then(() => {
            console.log("Message added successfully");
            window.scrollTo(0, 0);
        })
        .catch((error) => {
            console.error("Error adding message: ", error);
        });
        
        setTimeout(()=> {
            let indexKey= generateUniqueCode();
                  
            const currentDateTime = new Date().toLocaleString("en-US", {
                timeZone: "America/New_York", // Set to the desired USA timezone
            });
                
            const newMessageRef = ref(db, `dashboardMessage/${userId}/message/${indexKey}`);
                    
            set(newMessageRef, {
                indexKey,
                adminname: "Odds Admin",
                message: response,
                datetime: currentDateTime,
                msgtype: "Text",
            })
            .then(() => {
                console.log("Message added successfully");

                if(generate){
                    const formData = new FormData();
                    formData.set("order_id", order_id);
                    dispatch(generateOrders(formData))
                }
                
            })
            .catch((error) => {
                console.error("Error adding message: ", error);
            });
        }, 8000)
    }

    // Function to get a custom token from the backend
    const getCustomToken = async () => {
        try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/custom-auth`, {
            userIdentifier: process.env.REACT_APP_FIREBASE_IDENTIFICATION,  // Pass whatever identifier you require
        });
        return response.data.token;
        } catch (error) {
        console.error('Error fetching custom token:', error);
        }
    };

    // Function to authenticate using the custom token
    const authenticateWithCustomToken = async () => {
        const token = await getCustomToken();
        if (token) {
            try {
                const userCredential = await signInWithCustomToken(auth, token);
                setUserToken(userCredential.user);
                console.log('Authenticated with custom token:', userCredential.user);
            } catch (error) {
                console.error('Error signing in with custom token:', error);
            }
        }
    };

    useEffect(() => {
        authenticateWithCustomToken();
        // eslint-disable-next-line
    }, []);

    // handle get invoice
    const getInvoice = (id, type) => {
        setOrderId(id);
        setOrderType(type);
        setDisplayInvoice(true);
        dispatch(gteOrderInvoice(id, type))
    }

    useEffect(() => {

        if(userDetail && userDetail.userId){
            dispatch(getDeliveredOrders(userDetail.userId));
        }

        if(businessUserDetail && businessUserDetail.status === 1 ){
            setUserName(businessUserDetail.businessdata.restaurant_name.substring(0, 23));
            setUserId(businessUserDetail.businessdata.customer_id);
        }

        if (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            dispatch(clearOrderErrors());
        }
        
    }, [dispatch, userDetail, businessUserDetail, error, enqueueSnackbar]);

    return(
        <div className="delivered-order hidden sm:block mt-15px">
            <Table responsive className="delivered-orders-table">
                <thead>
                    <tr>
                        <th className="w-[20%]">Order ID</th>
                        <th className="w-[12%]">Customer Info</th>
                        <th className="w-[20%]">Contact Messages</th>
                        <th className="w-[10%]">Instant Message</th>
                        <th className="w-[10%]">Instant Message</th>
                        <th className="w-[12%]">Instant Message</th>
                        <th className="w-[2%]">Platform</th>
                        <th className="w-[5%]">Status</th>
                        <th className="w-[2%]">Image</th>
                    </tr>
                </thead>
                <tbody>
                    {deliveredOrder && deliveredOrder.updatedResults && deliveredOrder.updatedResults.length >= 1 && (
                        deliveredOrder.updatedResults.map((item, i) => (
                            <Fragment key={i}>
                                <tr key={i}>
                                    <td className="text-center">
                                        <p className="mb-0">Order ID {item.order_id} {item.delivered_by}</p>
                                        <p className="mb-0">{item.delivery_address}</p>
                                    </td>
                                    <td className="text-center">
                                        <p className="mb-0 font-semibold text-base">
                                            <span className="text-black">{item.delivery_firstname}</span>
                                            <br />
                                            {moment(item.mark_complete_time).format("MMM-D-Y h:m a")}
                                        </p>
                                    </td>
                                    <td className="text-center">
                                        {item.buying_ghost_order === 1 ?
                                            <span>Order Verification Complete</span>
                                        :
                                            <span className="cursor-pointer" onClick={() => handleMessgeCLick(item._id)}>{item.contact_message_text}</span>
                                        }
                                        
                                    </td>
                                    <td className="text-center">
                                        <div className="delivered_order_question" onClick={() => handleChatClick(item.custom_sms[0].question, item.custom_sms[0].response, item.order_id, true)}>
                                            {item.custom_sms[0].question_label}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="delivered_order_question" onClick={() => handleChatClick(item.custom_sms[1].question, item.custom_sms[1].response, item.order_id, true)}>
                                            {item.custom_sms[1].question_label}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="delivered_order_question" onClick={() => handleChatClick(item.custom_sms[2].question, item.custom_sms[2].response, item.order_id, false)}>
                                            {item.custom_sms[2].question_label}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        {item.order_type_img && (
                                            item.order_type === 4 || item.order_type === 3 || item.order_type === 6 || item.order_type === 2 ?
                                                <img src={item.order_type_img} alt="Order Type" className="w-full mx-auto cursor-pointer" onClick={()=> getInvoice(item._id, item.order_type)} />
                                            :
                                                <img src={item.order_type_img} alt="Order Type" className="w-full mx-auto" />
                                        )}
                                    </td>
                                    <td className="text-center">
                                        <p className="mb-2.5">{item.status_text}</p>
                                        <p className="mb-0 cursor-pointer" onClick={() => handleDeliveredTime(item._id)}>Delivered</p>
                                        <p className="mt-1.5 mb-0 text-black text-base hidden delTime" id={item._id+"_delTime"}>{moment(item.mark_complete_time).format("HH:mma")}</p>
                                    </td>
                                    <td className="text-center">
                                        {item.delivered_image_data !== ''
                                        ?
                                            <img src={item.delivered_image_data} alt="Delivered" className="w-[100px]" />
                                        :
                                            item.delivered_text_data
                                        }
                                    </td>
                                </tr>

                                {/* Orders messages */}
                                <tr ref={ref => allOrdersRefs[item._id] = ref} className="orders-button-messages hidden" id={item._id+"_ordermsg"} key={item._id+"_ordermsg"}>
                                </tr>
                            </Fragment>
                        ))
                    )}
                </tbody>
            </Table>


            {/* Order Invoice */}
            {displayInvoice &&
                <OrderInvoice id={orderId} orderType={orderType} setDisplayInvoice={setDisplayInvoice} />
            }

        </div>
    )
}

export default DeliveredOrders