import { Navbar } from "react-bootstrap"
import logo from '../../../assests/images/odds-logo.png';
import romanticLogo from '../../../assests/images/romantic_depot_logo.png';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const DashboardHeader = () => {

    const [isRomantic, setIsRomantic] = useState(false);

    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);

    useEffect(() => {
        if(loading === false && businessUserDetail && businessUserDetail.status === 1){
            setIsRomantic(businessUserDetail.businessdata.mark_romantic && businessUserDetail.businessdata.mark_romantic === 1 ? true : false);
        }
    }, [loading, businessUserDetail]);

    return(
        <Navbar className="dashboard-navbar ">
            <div className="max-w-screen-2xl mx-auto w-full flex flex-col md:flex-row items-center pt-30px gap-2.5">
                <div className="w-full md:w-1/3 px-12px">
                    <Navbar.Brand href='/dashboard' className='p-0 m-0'>
                        {isRomantic ?
                            <img src={romanticLogo} alt='Romantic Depot Logo' className='logo mx-0 h-[100px]' />
                        :
                            <img src={logo} alt='ODDS Logo' className='logo mx-0 h-[50px]' />
                        }
                    </Navbar.Brand>
                </div>
                <div className="hidden sm:block w-full md:w-2/3 px-12px">
                    {isRomantic ?
                        <div className="header-heading-section rounded-full bg-primary-headerBlue text-white center md:text-right py-2 px-[20px] lg:px-5 text-xl lg:text-2xl max-w-700 md:ml-auto">
                            The Fastest and Easiest <b>Logistical Delivery System</b>
                        </div>
                    :
                        <div className="header-heading-section rounded-full bg-primary-headerBlue text-white center md:text-right py-2 px-[20px] lg:px-5 text-xl lg:text-2xl max-w-700 md:ml-auto">
                            Built In POS - Combine All Your Tablets Into <b>ONE</b>
                        </div>
                    }
                </div>
            </div>
        </Navbar>
    )
}

export default DashboardHeader