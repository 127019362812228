import { useSelector } from "react-redux";
import './Chat.css';
import sendMsgIcon from '../../assests/images/business/send-msg-icon.png';
import { useEffect, useState } from "react";
import { auth, db, get, set, push, ref, onValue } from '../../Firebase';
import { signInWithCustomToken } from 'firebase/auth';
import moment from "moment/moment";
import axios from "axios";

const ChatSection = () => {

    const { businessUserDetail } = useSelector((state) => state.businessUserDetails);
    const {chatAdmin} = useSelector((state) => state.chatAdmin)

    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [adminName, setAdminName] = useState('');
    const [adminImage, setAdminImage] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [timezone, setTimezone] = useState('');

    const [userToken, setUserToken] = useState(null);

    const generateUniqueCode = () => {
        return moment().unix();
    };

    // Add new message
    const addMessage = async () => {

        if (!userToken) {
            console.log('User is not authenticated');
            return;
        }

        if(message !== ''){
            const messagesRef = ref(db, `dashboardMessage/${userToken.uid}/${userId}/message`);
            const messageRef = push(messagesRef);
            let indexKey;
            let exists = true;

            while (exists) {
                indexKey = generateUniqueCode();
                const snapshot = await get(messageRef);
                const existingMessages = snapshot.val();
                exists =
                    existingMessages &&
                    Object.values(existingMessages).some(
                    // eslint-disable-next-line
                    (msg) => msg.indexKey === indexKey
                );
            }

            const currentDateTime = new Date().toLocaleString("en-US", {
                timeZone: "America/New_York", // Set to the desired USA timezone
            });
        
            const newMessageRef = ref(db, `dashboardMessage/${userToken.uid}/${userId}/message/${indexKey}`);
            
            set(newMessageRef, {
                indexKey,
                adminname: userName,
                message: message,
                datetime: currentDateTime,
                msgtype: "Question",
            })
            .then(() => {
                console.log("Message added successfully");
                setMessage(''); 
            })
            .catch((error) => {
                console.error("Error adding message: ", error);
            });
        }
    };

    const handleEnterKey = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          addMessage();
        }
    };

    // Function to get a custom token from the backend
    const getCustomToken = async () => {
        try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/custom-auth`, {
            userIdentifier: process.env.REACT_APP_FIREBASE_IDENTIFICATION,  // Pass whatever identifier you require
        });
        return response.data.token;
        } catch (error) {
        console.error('Error fetching custom token:', error);
        }
    };

    // Function to authenticate using the custom token
    const authenticateWithCustomToken = async () => {
        const token = await getCustomToken();
        if (token) {
            try {
                const userCredential = await signInWithCustomToken(auth, token);
                setUserToken(userCredential.user);
                console.log('Authenticated with custom token:', userCredential.user);
            } catch (error) {
                console.error('Error signing in with custom token:', error);
            }
        }
    };

    useEffect(() => {
        authenticateWithCustomToken();
        // eslint-disable-next-line
    }, []);
  
    useEffect(() => {
        if(businessUserDetail && businessUserDetail.status === 1 ){
            setUserName(businessUserDetail.businessdata.restaurant_name.substring(0, 23));
            setUserId(businessUserDetail.businessdata.customer_id);
            setTimezone(businessUserDetail.businessdata.timezone);
        }

        if(chatAdmin && chatAdmin.length >= 0){
            setAdminName(chatAdmin[0].name);
            setAdminImage(chatAdmin[0].image);
        }

    }, [businessUserDetail, chatAdmin])

    useEffect(() => {
        if(userId && userToken && userToken.uid){
            const messagesRef = ref(db, `dashboardMessage/${userToken.uid}/${userId}/message`);
            onValue(messagesRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const messagesArray = Object.values(data); 

                    const updatedMessages = messagesArray.map((message) => {

                        if(timezone === 1){
                            const updatedDate = moment(message.datetime, "MM/DD/YYYY, h:mm:ss A")
                                .subtract(60, 'minutes')
                                .format("MM/DD/YYYY, h:mm:ss A"); 
                            return { ...message, datetime: updatedDate };
                        } else if(timezone === 2){
                            const updatedDate = moment(message.datetime, "MM/DD/YYYY, h:mm:ss A")
                                .subtract(120, 'minutes')
                                .format("MM/DD/YYYY, h:mm:ss A"); 
                            return { ...message, datetime: updatedDate };
                        } else if(timezone === 3){
                            const updatedDate = moment(message.datetime, "MM/DD/YYYY, h:mm:ss A")
                                .subtract(180, 'minutes')
                                .format("MM/DD/YYYY, h:mm:ss A"); 
                            return { ...message, datetime: updatedDate };
                        } else {
                            return { ...message };
                        }
                        
                    });

                    setMessages(updatedMessages.reverse());
                } else {
                    setMessages([]);
                }
            });
        }
       
    }, [userId, timezone, userToken]);

    return(
        <div className="chat-section">
            <h4 className="text-[28px] text-center font-bold font-asap mb-20px">{userName}</h4>
            <div className="chat-block">
                <div className="welcome-msg-btn">Support Chat</div>
                <div className="chat-body">
                    <div className="message-parent">
                        <div className="message">
                            <div className="flex gap-2.5 items-center">
                                <div className="w-1/4">
                                    { adminImage ? 
                                        <img src={`${process.env.REACT_APP_API_URL}/images/${adminImage}`} alt="Profile-img" className="rounded-full h-[90px] w-[90px] object-cover" />
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <div className="w-3/4 content">
                                    <p>Hello {userName}, please let us know if we can assist you in any way. <br/>
                                    {adminName}
									</p>
                                </div>
                            </div>
                            <div className="inner_msg_box">
                                {messages && messages.map((msg,i) => (
                                    <div className={`message_box ${msg.msgtype.toLowerCase()} ${msg.msgtype.toLowerCase() === 'text' ? 'admin_msg' : ''}`} key={i} id={msg.indexKey}>
                                       <p className="message-adminname">{msg.adminname}</p>
                                       <p className="message-text">{msg.message}</p>
                                       <p className="message-time">{msg.datetime}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat-typing">
                    <div className="typing-group relative">
                        <input type="text" className="chat_msg_send" placeholder="Type message" name="chat_msg" id="chat_msg" autoComplete="off" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={(e) => handleEnterKey(e)} />
                        <img src={sendMsgIcon} className="send-msg-icon" alt="Send Msg" onClick={addMessage} />
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ChatSection