import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MetaData from "../../../layout/MetaData";
import './DriverDashboard.css';
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import appApple from '../../../assests/images/driver/app-apple.png';
import appGoogle from '../../../assests/images/driver/app-google.png';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clearErrors, detailDriverUser } from "../../../store/actions/driverActions/driverUsers";
import { emptyBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import DriverForm from './DriverForm'

function DriverDashboard(){

    const dispatch = useDispatch();

    const { userDetail } = useSelector((state) => state.userDetails);
    const { error } = useSelector((state) => state.driverUserDetails);

    useEffect(() => {
        if(userDetail && userDetail.userId){
            var userDetails = {
                obj_id: userDetail.userId,
                email: userDetail.userEmail,
                customer_id: userDetail.customerId,
            };
            dispatch(detailDriverUser(userDetails));
        }

        if(error){
            dispatch(emptyBusinessSession());
            dispatch(clearErrors());
        }

    }, [dispatch, error, userDetail])

    return(
        <>
            <MetaData
                title={"Driver Dashboard"}
            />

            <section className="driver_dashboard">
                <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto px-12px py-20">
                    <p className="font-bold text-22">Drivers Register Now and Get Your First Pay Check Today: </p>
                    <p className="font-bold text-22">Full Time Or Part Time Driving Positions Available In All 50 States.</p>
                    <p className="font-bold text-22">Step 1: Register as a driver below & download The Buying.com driver application: Available for Apple or Android</p>
                    <p className="font-bold text-22">Step 2: Choose Full Time Or On Demand.</p>
                    <p className="font-bold text-22">The On Demand Program allows you to be notified of Pickups in your area. The program works as follows: you will be sent a text message when there is a pick up in your local area where you live based on your registration address that you provided. You can choose to accept that order if you are available and you must be at the restaurant location within 15 minutes. Do not accept an order if you can not make it to the restaurant within the 15 minute pick up time, being late for a pick up will violate your ability to make other deliveries in the future. All deliveries within a 3-mile radius of your address will be sent to you. The faster you respond will assure you more orders. If you choose you want to take the order, then you will press accept on your phone with the Buying.com app and except that order. The amount you will be paid will be listed in the text message for you. Some trips that are longer distances will pay more which will be indicated. Once the pickup and delivery is completed you are paid at the end of the day to your bank account. Turn notifications on and off based on your schedule. Be at home and take an order when you are free. Available 7 days a week on Demand.</p>

                    <DriverForm />

                    <div className="py-20px text-center">
                        <p className="font-bold text-xl uppercase">Please text the admin for assistance or scheduling at </p>
                        <div className="flex flex-wrap gap-3 justify-center align-items-center">
                            <a href="tel:862-772-8469" className="driver-btn">
                                <FontAwesomeIcon icon={faPhone} className="mx-1" /> 862-772-8469
                            </a>
                            <a href="mailto:info@buying.com" className="driver-btn">
                                <FontAwesomeIcon icon={faEnvelope} className="mx-1" /> Send Email
                            </a>
                        </div>
                    </div>

                    <div className="pt-50 flex flex-wrap gap-3 justify-center align-items-center">
                        <p className="font-black text-3xl w-full text-center mb-0">Driver App</p>
                        <a href="https://apps.apple.com/us/app/driver-app-buying-com/id1451470534" target="_blank" rel="noreferrer">
                            <img src={appApple} alt="App Apple" className="w-36 object-contain" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.buying.driverapp" target="_blank" rel="noreferrer">
                            <img src={appGoogle} alt="App Google" className="w-36 object-contain" />
                        </a>
                    </div>
                    
                </div>
            </section>
        </>
    )
}

export default DriverDashboard