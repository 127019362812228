import { Button, Form } from "react-bootstrap"
import MetaData from "../../../layout/MetaData"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import './SalesLogin.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { registerType } from "../../../store/actions/registerTypesAction";
import { RESET_BUSINESS } from "../../../store/types/businessTypes/businessUsers";
import { autoLoginUserFromAdmin, clearErrors, emptyBusinessSession, loginBusinessUser, setBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import { useSnackbar } from "notistack";

const SalesLogin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();

    const { types } = useSelector((state) => state.registerType);
    const { isAuthenticated, error, user } = useSelector((state) => state.businessUser);

    const { userDetail } = useSelector((state) => state.userDetails);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [groupId, setGroupId] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        var group = types && types.filter(item => item.name === 'sales_person');
        var customer_group_id = group && group[0].customer_group_id;

        const formData = new FormData();
        formData.set("email", email);
        formData.set("password", password);
        formData.set("customer_group_id", customer_group_id);

        dispatch(loginBusinessUser(formData));
    }

    // Fetching user Id from URL for Auto login
    useEffect(() => {
        const fetchUserId = () => {
            const pathParts = location.pathname.split('/');
            return pathParts[pathParts.length - 1];
        };
    
        const userId = fetchUserId();
        if (location.pathname === `/login/sales/${userId}`) {
            
            dispatch(emptyBusinessSession()); 
            dispatch({ type: RESET_BUSINESS});

            const formData = new FormData();
            formData.set("_id", userId);
            formData.set("group_id", groupId ? groupId : 3);

            dispatch(autoLoginUserFromAdmin(formData));

        }
    }, [dispatch, location.pathname, groupId]);
    // End Fetching user Id from URL for Auto login
    
    useEffect(() => {
        if(types && types.length >= 1){
            var group = types && types.length >= 1 && types.filter(item => item.name === 'sales_person');
            var customer_group_id = group[0].customer_group_id;
            setGroupId(customer_group_id)
        }
    }, [types])

    useEffect(() => {

        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
            
        }

        if (isAuthenticated) {
            var userDetails = {
                userId: user._id,
                userEmail: user.email,
                customerGroup: user.customer_group_id,
                customerId: user.customer_id,
                userName: user.firstname,
            }
            dispatch(setBusinessSession(userDetails))

            dispatch({ type: RESET_BUSINESS});
        }

        if(userDetail && userDetail.userId){
           
            //console.log(userDetail) 
            navigate('/saleperson');
        }

        dispatch(registerType());
        
    }, [dispatch, isAuthenticated, error, user, userDetail, navigate, enqueueSnackbar])

    return(
        <>
            <MetaData
                title={"Account Login"}
            />

            {/* Login section */}
            <section className="sales-login sales-pages px-20px py-60px">
                <div className="max-w-screen-750 mx-auto p-6 sm:p-10 flex flex-col bg-primary-bgForms rounded-[20px]">
                    <h2 className="text-center mb-0 pb-10">Sales Login</h2>
                    <Form className="sales-login-form sales-form" method="post" onSubmit={handleSubmit}>

                        <Form.Group className="mb-10 relative" controlId="email_address">
                            <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
                            <Form.Control type="email" placeholder="Email" name="email_address" required value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="username" />
                        </Form.Group>

                        <Form.Group className="mb-20px relative" controlId="password">
                            <FontAwesomeIcon icon={faLock} className="input-icon" />
                            <Form.Control type="password" placeholder="Password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
                        </Form.Group>

                        <p className="text-right">Forgot Password? Click <a href="/forgot-password" className="no-underline">here</a></p>
                        
                        <Form.Group className="text-center">
                            <Button type="submit" className="btn-submit mt-10">Login</Button>
                        </Form.Group>
                    </Form>
                </div>
            </section>
        </>
    )
}

export default SalesLogin