import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, group, isConsumer, isSales, isDriver }) => {
  const { userDetail } = useSelector((state) => state.userDetails);

  return (
    <>
      {userDetail && userDetail.userId ? (
        userDetail.customerGroup === group ? (
          children
        ) : (
          <Navigate to="/" />
        )
      ) : isConsumer ? (
        <Navigate to="/consumerlogin" />
      ) : isDriver ? (
        <Navigate to="/drivers-registration" />
      ) : isSales ? (
        <Navigate to="/loginsales" />
      ): (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default ProtectedRoute;
