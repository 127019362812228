import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { clearErrors, loginDriverUser } from "../../../store/actions/driverActions/driverUsers";
import { setBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import { RESET_DRIVER } from "../../../store/types/driverTypes/driverUsers";
import { registerType } from "../../../store/actions/registerTypesAction";
import MetaData from "../../../layout/MetaData";

import shape5 from '../../../assests/images/refer/shape5.png';
import shape2 from '../../../assests/images/about/shape2.png';
import shape1 from '../../../assests/images/business/shape1.png';
import { Button, Form } from "react-bootstrap";

function DriverLogin(){

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { types } = useSelector((state) => state.registerType);
    const { userDetail } = useSelector((state) => state.userDetails);
    const { isAuthenticated, error, user } = useSelector((state) => state.driverUser);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const messageRef = useRef(null);

    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();

        var group = types && types.filter(item => item.name === 'driver');
        var customer_group_id = group && group[0].customer_group_id;

        const formData = new FormData();
        formData.set("email", email);
        formData.set("password", password);
        formData.set("customer_group_id", customer_group_id);

        dispatch(loginDriverUser(formData));
    }

    useEffect(() => {
        if(error){
            setErrorMessage(error);
            messageRef.current.focus();

            setTimeout(() => {
                setErrorMessage('');
                dispatch(clearErrors());
            }, 10000);
        }

        if(isAuthenticated){
            var userDetails = {
                userId: user._id,
                userEmail: user.email,
                customerGroup:user.customer_group_id,
                customerId: user.customer_id,
                userName: user.firstname,
            }

            dispatch(setBusinessSession(userDetails));

            dispatch({ type: RESET_DRIVER });
        }

        if(userDetail && userDetail.userId){
            navigate('/driverdashboard');
        }

        dispatch(registerType);

    }, [dispatch, isAuthenticated, navigate, userDetail, error, user]);

    return(
        <>
            <MetaData
                title={"Driver Log In"}
            />

            {/* Login section */}
            <section className="business-login relative">
                <img src={shape1} alt="Shape1" className="block max-w-[125px] absolute top-[50px] right-[8%] -z-10" />

                <div className="max-w-screen-1xl mx-auto py-100px flex flex-col relative">
                    <div className="px-15px">
                        <h1 className="text-5xl md:text-[58px] font-semibold text-center mb-0">Driver Login</h1>
                    </div>

                    <div tabIndex="-1" ref={messageRef} className="message text-center">
                        {errorMessage && (
                            <div className="text-red-500">{errorMessage}</div>
                        )}
                    </div>

                    <div className="mt-20px px-15px">
                        <div className="form business-login-register max-w-700 mx-auto py-8 md:py-10 px-0 sm:px-8 md:px-16">
                            <Form className="form-login-register form" onSubmit={handleSubmit} method="post">
                                <Form.Group className="mt-20px" controlId="email_address">
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Email" 
                                        required
                                        name="email_address" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-20px" controlId="password">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        required
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-20px text-center">
                                    <Button type="submit" className="btn-submit mt-50 md:mt-100px">Log in</Button>
                                </Form.Group>

                                <p className="get-started text-xl mt-20px text-center">Don't have an account? <a href="/drivers-registration" className="btn-stated text-primary-blue">Get Started</a></p>
                            </Form>
                        </div>
                    </div>
                </div>
                <img src={shape5} alt="Shape5" className="block max-w-[85px] absolute -z-10 top-[40%] left-[5%]" />
                <img src={shape2} alt="Shape2" className="block max-w-[125px] absolute z-10 -bottom-[45px] right-[4%]" />
            </section>
        </>
    )
}

export default DriverLogin