import { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap"
import { usePlacesWidget } from "react-google-autocomplete";
import marker from '../../../../assests/images/business/marker.png'
import TimePicker from 'react-bootstrap-time-picker';
import { useDispatch, useSelector } from "react-redux";
import { DirectionsRenderer, GoogleMap } from '@react-google-maps/api';
import { Modal } from "react-bootstrap";
import { useTab } from "../TabContext";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { clearErrors, detailBusinessUser, updateBusinessProfileCC, updateBusinessRadius } from "../../../../store/actions/businessActions/businessUsers";
import { UPDATE_BUSINESS_CC_RESET, UPDATE_BUSINESS_DATA_RESET } from "../../../../store/types/businessTypes/businessUsers";
import { clearOrderErrors, myInternalOrders, newInternalOrderData } from "../../../../store/actions/businessActions/businessOrders";
import { useSnackbar } from "notistack";
import ScanDrivers from "../ScanDrivers/ScanDrivers";
import { timeFromInt } from 'time-number';
import { timeToInt } from 'time-number';
import ChatSection from "../../../../components/ChatComponent/ChatSection";
import Loading from "../../../../layout/Loading";

const DriverNowSection = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { success, error } = useSelector((state) => state.newInternalOrder);

    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);
    const { errorCC, isCCUpdated, isRadiusUpdated, errorRadius } = useSelector((state) => state.businessProfile);
    const [objectId, setObjectId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [restId, setRestId] = useState('');
    const [businessId, setBusinessId] = useState('');
    const [restaurantEmail, setRestaurantEmail] = useState('');

    const [deliveryName, setDeliveryName] = useState('');
    const [deliveryMobile, setDeliveryMobile] = useState('');
    const [deliveryNotes, setDeliveryNotes] = useState('');
    const [deliveryAddress, setDeliverAddress] = useState('');
    // const [deliveryStreet, setDeliverStreet] = useState('');
    const [deliveryState, setDeliverState] = useState('');
    const [deliveryCity, setDeliverCity] = useState('');
    const [deliveryZip, setDeliverZip] = useState('');
    // const [deliveryCountry, setDeliverCountry] = useState('');
    const [deliveryLat, setDeliverLat] = useState('');
    const [deliveryLong, setDeliverLong] = useState('');

    const [deliveryType, setDeliveryType] = useState(false)
    const [preOrder, setPreOrder] = useState(false)
    const [deliveryDate, setDeliveryDate] = useState(new Date().toISOString().split("T")[0])
   
    const [deliveryTime, setDeliveryTime] = useState(timeToInt(getRoundedTime()));
    const [startTime, setStartTime] = useState("00:00");
    const [rangeVal, setRangeVal] = useState(4);
    const [rangeChange, setRangeChange] = useState(false);

    const [restaurantAddress, setRestaurantAddress] = useState('');
    const [restDeliveryAmount, setRestDeliveryAmount] = useState('');
    const [restMileageAmount, setRestMileageAmount] = useState('');
    const [calculateText, setCalculateText] = useState('');
    const [calculateLoading, setCalculateLoading] = useState('');

    const [show, setShow] = useState(false);
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [mapDistance, setMapDistance] = useState("");
    const [mapTime, setMapTime] = useState("");
    const [mapLat, setMapLat] = useState("");
    const [mapLong, setMapLong] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showCard, setShowCard] = useState(false);
    const handleCardClose = () => setShowCard(false);
    const handleCardShow = () => setShowCard(true);

    // credit card forms
    const [userName, setUserName] = useState('');
    const [accountNumber, setAccountNumber] = useState(''); 
    const [ccExpirationMonth, setCcExpirationMonth] = useState(''); 
    const [ccExpirationYear, setCcExpirationYear] = useState('');
    const [ccCvv, setCcCvv] = useState(''); 
    const [hasCard, setHasCard] = useState(false); 

    const [loadingOrder, setLoadingOrder] = useState(false);

    const [years, setYears] = useState([]);

    const [showDrivers, setShowDrivers] = useState(false);
    const [isRomantic, setIsRomantic] = useState(false);

    const handleDeliveryTypeChange = () => {
        setDeliveryType(!deliveryType)
    }

    const handlePreOrderChange = () => {
        setPreOrder(!preOrder)
    }

    const handleAddressChange = (e) =>{
        setDeliverAddress(e.target.value);
        setDeliverCity('');
        setDeliverState('');
        setDeliverZip('');
    }

    const handleFormReset = () =>{
        setDeliverAddress('');
        setDeliverCity('');
        setDeliverState('');
        setDeliverZip('');
        setDeliveryName('');
        setDeliveryMobile('');
        setDeliveryNotes('');
        setDeliverLat('');
        setDeliverLong('');
        setDeliveryType(false);
        setPreOrder(false);
        setDeliveryDate(new Date().toISOString().split("T")[0]);
        setDeliveryTime(null)
        setCalculateText('');
    }

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if (place) {

                setDeliverAddress(place.formatted_address);

                const addressComponents = place.address_components;
                // console.log(addressComponents)
        
                // Extract address components
                // const streetAddress = addressComponents.find(component =>
                //     component.types.includes('route')
                // )?.long_name || '';

                // setDeliverStreet(streetAddress);
        
                const postalCode = addressComponents.find(component =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                setDeliverZip(postalCode)
        
                const city = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setDeliverCity(city)
        
                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setDeliverState(state)

                // const country = addressComponents.find(component =>
                //     component.types.includes('country')
                // )?.short_name || '';

                // setDeliverCountry(country);

                const lat = place.geometry?.location.lat() || '';
                const lng = place.geometry?.location.lng() || '';

                setDeliverLat(lat);
                setDeliverLong(lng);
            }
            
        },
        options: {
          types: ["address"],
          componentRestrictions: { country: "us" },
        },
    });

    // Get Current time
    function getRoundedTime() {
        const date = new Date();
        const minutes = date.getMinutes();
        let roundedMinutes;

        if (minutes < 15) {
            roundedMinutes = 0; // Round to the start of the hour
        } else if (minutes < 30) {
            roundedMinutes = 15; // Round to 15
        } else if (minutes < 45) {
            roundedMinutes = 30; // Round to 30
        } else {
            roundedMinutes = 45; // Round to 45
        }
        return date.getHours()+':'+roundedMinutes;
    }

    // order form submit
    const handleSubmit = (e) => {
        e.preventDefault();

        if(deliveryAddress === ''){
            enqueueSnackbar("Choose Address for delivery from dropdown", { variant: "error" });
            return false;
        }

        if(deliveryState === ''){
            enqueueSnackbar("Choose Address for delivery from dropdown", { variant: "error" });
            return false;
        }
        if(deliveryCity === '' ){
            enqueueSnackbar("Choose Address for delivery from dropdown", { variant: "error" });
            return false;
        }
        if(deliveryZip === ''){
            enqueueSnackbar("Choose Complete Address for delivery from dropdown", { variant: "error" });
            return false;
        }

        setLoadingOrder(true);

        const formData = new FormData();
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);
        formData.set("business_id", businessId);
        formData.set("delivery_firstname", deliveryName);
        formData.set("delivery_email", restaurantEmail);
        formData.set("delivery_phone", deliveryMobile);
        formData.set("order_notes", deliveryNotes);
        formData.set("delivery_address", deliveryAddress);
        formData.set("delivery_state", deliveryState);
        formData.set("delivery_city", deliveryCity);
        formData.set("delivery_postcode", deliveryZip);
        formData.set("delivery_lat", deliveryLat);
        formData.set("delivery_long", deliveryLong);
        formData.set("catering_order", deliveryType === true ? 1 : 0);
        formData.set("pre_order", preOrder === true ? 1 : 0);
        formData.set("delivery_datetime", preOrder === true ? deliveryDate + " " + timeFromInt(deliveryTime) : "");
        
        dispatch(newInternalOrderData(formData))
    }

    // Refresh btn click
    function refreshPage() {
        window.location.reload(false);
    }
    
    // calculate btn click
    const handleCalculate = () => {
        if(deliveryAddress && restaurantAddress){
            setCalculateLoading(true)

            const distanceService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            distanceService.route(
                {
                    origin: restaurantAddress,
                    destination: deliveryAddress,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                if (status === 'OK') {
                   
                    setDirectionsResponse(response);
                    directionsRenderer.setDirections(response);

                    var dis =response.routes[0].legs[0].distance.value/1000 ;
                    var time1 =response.routes[0].legs[0].duration.value/60 ;
                    var time=time1.toFixed(0);
                    setMapTime(time)
                   
                    var dis1=dis.toFixed(2)* 0.62;
                                            
                    var newdis=dis1.toFixed(2);
                    var restaurant_help_amount=restDeliveryAmount;

                    var geocoder = new window.google.maps.Geocoder();
                    geocoder.geocode( { 'address': deliveryAddress }, (results, status) => {
                        if (status === 'OK') {
                            var lat = (results[0].geometry.location.lat()).toFixed(4);
							var lng = results[0].geometry.location.lng().toFixed(4);
                            setMapLat(lat);
                            setMapLong(lng);
                        }
                    })
                       
                    if(newdis > 10)
                    {
                                                    
                        var newdis1=newdis - 10;

                        var total1=newdis1*restMileageAmount;
                      
                        var final_trip_price = parseFloat(restaurant_help_amount)+parseFloat(total1);
                        setCalculateText("Delivery Fee For This Trip Is $"+final_trip_price.toFixed(2));
                        setCalculateLoading(false)

                        var newdistance=parseFloat(dis1)+parseFloat(2.05);
                        setMapDistance(newdistance.toFixed(2));

                    }
                    else
                    {
                        setCalculateText("Delivery Fee For This Trip Is Local $"+restaurant_help_amount);
                        setCalculateLoading(false);

                        setMapDistance(dis1.toFixed(2))
                    }
                } else {
                    alert('Error fetching distance:', status);
                    setCalculateLoading(false)
                }
                }
            ); 
        }
        
    }

    // handle range change
    const handleRangeChange = (val) => {
        setRangeVal(val);

        setRangeChange(true)

    }

    // handle more orders click
    const { setActiveKey } = useTab();

    const handleChangeTab = (tabKey) => {
        setActiveKey(tabKey);
    };

    // handle card form reset
    const handleCardReset = () => {
        setAccountNumber("");
        setCcExpirationMonth("");
        setCcExpirationYear("");
        setCcCvv("");
    }

    // credit card form submit
    const handleCardSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set("cc_number", accountNumber);
        formData.set("cc_expiration", ccExpirationMonth+"/"+ccExpirationYear);
        formData.set("cc_cvv", ccCvv);
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);

        dispatch(updateBusinessProfileCC(formData))

    }

    // handle delivery mobile number
    const handleDeliveryMobile = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setDeliveryMobile(value);
    }

    // handle delivery date change
    const handleDeliveryDate = (e) =>{
        // const currentDate = new Date().toISOString().split('T')[0];
        //if(currentDate !== e.target.value){
            setStartTime("00:00");
        // } else {
        //     setStartTime(getRoundedTime())
        // }
        setDeliveryDate(e.target.value);
    }

    useEffect(() =>{

        var userDetails = {
            obj_id: objectId,
            email: restaurantEmail,
            customer_id: customerId,
        }
        // prefill user details
        if(loading === false && businessUserDetail && businessUserDetail.status === 1){
            setRestaurantAddress(businessUserDetail.businessdata.restaurant_address ? businessUserDetail.businessdata.restaurant_address : '');
            setRestDeliveryAmount(businessUserDetail.businessdata.restaurant_delivery_amount ? businessUserDetail.businessdata.restaurant_delivery_amount : '');
            setRestMileageAmount(businessUserDetail.businessdata.restaurant_mileage_amount ? businessUserDetail.businessdata.restaurant_mileage_amount : '');
            setRangeVal(businessUserDetail.businessdata.radius_mileage ? businessUserDetail.businessdata.radius_mileage : 4);
            setUserName(businessUserDetail.user.firstname + " "+ businessUserDetail.user.lastname);
            setObjectId(businessUserDetail.user._id)
            setCustomerId(businessUserDetail.user.customer_id);
            setRestaurantEmail(businessUserDetail.businessdata.restaurant_email);
            setBusinessId(businessUserDetail.businessdata.business_id);
            setRestId(businessUserDetail.businessdata._id);
            setIsRomantic(businessUserDetail.businessdata.mark_romantic && businessUserDetail.businessdata.mark_romantic === 1 ? true : false);

            if(businessUserDetail.businessdata.cc_number && businessUserDetail.businessdata.cc_expiration && businessUserDetail.businessdata.cc_cvv){
                setHasCard(true);
            } else {
                setHasCard(false);
            }
        }

        // Year dropdown
        const currentYear = new Date().getFullYear();
        const yearsArray = [];
        for (let i = 0; i < 15; i++) {
        yearsArray.push(currentYear + i);
        }
        setYears(yearsArray);

        // Profile CC Error
        if (errorCC) {
            enqueueSnackbar(errorCC, { variant: "error" });
            dispatch(clearErrors());
        }

        // Profile CC updated
        if (isCCUpdated) {
            
            dispatch(detailBusinessUser(userDetails))
            dispatch({ type: UPDATE_BUSINESS_CC_RESET });
            setShowCard(false)
            enqueueSnackbar("Card Details Updated Successfully", { variant: "success" });
        }

        if (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            dispatch(clearOrderErrors());
        }

        if(success){
            setLoadingOrder(false);
            enqueueSnackbar("Order Created Successfully", { variant: "success" });
            dispatch(myInternalOrders(objectId));
            handleFormReset();
            dispatch(clearOrderErrors());
        }

        if (errorRadius) {
            console.log(errorRadius)
            dispatch(clearOrderErrors());
        }

        if(isRadiusUpdated){
            dispatch({ type: UPDATE_BUSINESS_DATA_RESET });
            dispatch(detailBusinessUser(userDetails))
            setRangeChange(false)
            setRangeVal(rangeVal)
        }

        if(rangeChange){
            
            setRangeChange(false)

            const formData = {
                "_id" : restId,
                "radius_mileage": rangeVal,
            }
    
            dispatch(updateBusinessRadius(formData))
        }

    }, [dispatch, businessUserDetail, loading, isCCUpdated, errorCC, objectId, restaurantEmail, customerId, enqueueSnackbar, error, success, errorRadius, isRadiusUpdated, rangeChange, restId, rangeVal])

    // get year as 2 digit
    const getTwoDigitYear = (year) => year % 100;

    return(
        <div className="driver-now-section">

            {loadingOrder && <Loading /> }
           

            <div className="flex flex-col md:flex-row -mx-12px">
                {/* form column */}
                <div className="w-full md:w-2/3 px-12px">
                    <h4 className="text-[28px] text-center font-bold font-asap mb-20px">FILL IN ADDRESS INFO & PRESS DRIVER NOW</h4>

                    <Form className="form-driver-now" onSubmit={handleSubmit} method="post">

                        <div className="flex flex-col lg:flex-row gap-[20px]">
                            <Form.Group as={Col} controlId="delivery_name">
                                <Form.Label>Name- Of Person Receiving Order</Form.Label>
                                <Form.Control type="text" name="delivery_name" value={deliveryName} onChange={(e) => setDeliveryName(e.target.value)} required/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="delivery_mobile">
                                <Form.Label>Phone number (For Delivery)</Form.Label>
                                <Form.Control type="tel" name="delivery_mobile" minLength={10} maxLength={10} value={deliveryMobile} onChange={handleDeliveryMobile} required />
                            </Form.Group>
                        </div>
                        
                        <div className="flex flex-col lg:flex-row gap-[20px] mt-15px">
                            <Form.Group as={Col} controlId="delivery_address" className="relative">
                                <Form.Label>Address for delivery: Choose from dropdown</Form.Label>
                                <Form.Control ref={ref} type="text" placeholder="Enter a location" name="delivery_address" required onChange={handleAddressChange} value={deliveryAddress}/>
                                <img src={marker} className="marker-location" alt="marker" />
                            </Form.Group>

                            <Form.Group className="w-full lg:w-1/4 relative">
                                <button type="button" className="calculate_button" onClick={handleCalculate}>{calculateLoading? "Loading.." : "Calculate"}</button>
                                
                            </Form.Group>
                        </div>
                        <div className="text-right mt-2.5 text-sm">
                            {calculateText && (
                                <>
                                    {calculateText} <button type="button" onClick={handleShow} className="text-blue-500">Map</button>
                                </>
                            )}
                        </div>

                        <Form.Group className="mt-15px" controlId="delivery_notes">
                            <Form.Label>Special Instructions: Enter business Name, Gate# Code#, Apt# Bldg#, Room# Location to Leave Package</Form.Label>
                            <Form.Control type="text" name="delivery_notes" value={deliveryNotes} onChange={(e) => setDeliveryNotes(e.target.value)}/>
                        </Form.Group>

                        <div className="flex flex-col lg:flex-row gap-[20px] mt-25px">
                            <div className="w-full lg:w-3/4">
                                <div className="w-full hidden sm:flex flex-col lg:flex-row -mx-12px switch-label">

                                    {/* Catering */}
                                    {!isRomantic && 
                                    <div className="w-full lg:w-2/12 px-12px">
                                        <label className='flex flex-col cursor-pointer select-none items-start'>
                                            <span className='label flex items-center text-black mb-2.5'>
                                                Catering
                                            </span>
                                            <div className='relative'>
                                                <input
                                                    type='checkbox'
                                                    checked={deliveryType}
                                                    onChange={handleDeliveryTypeChange}
                                                    className='sr-only'
                                                    name="delivery_type"
                                                />
                                                <div
                                                    className={`switch-icon box block h-8 w-14 rounded-full ${
                                                        deliveryType ? 'bg-primary-dashBlue' : 'bg-white'
                                                    }`}
                                                ></div>
                                                <div
                                                    className={`switch-move-icon absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full  border-2 transition ${
                                                        deliveryType ? 'border-white bg-primary-dashBlue translate-x-full' : 'bg-white border-primary-dashBlue'
                                                    }`}
                                                ></div>
                                            </div>
                                            
                                        </label>
                                    </div>
                                    }
                                    
                                    {/* Pre order */}
                                    <div className="w-full lg:w-2/12 px-12px">
                                        <label className='flex flex-col cursor-pointer select-none items-start'>
                                            <span className='label flex items-center text-black mb-2.5'>
                                                Pre Order
                                            </span>
                                            <div className='relative'>
                                                <input
                                                    type='checkbox'
                                                    checked={preOrder}
                                                    onChange={handlePreOrderChange}
                                                    className='sr-only'
                                                    name="pre_order"
                                                />
                                                <div
                                                    className={`switch-icon box block h-8 w-14 rounded-full ${
                                                        preOrder ? 'bg-primary-dashBlue' : 'bg-white'
                                                    }`}
                                                ></div>
                                                <div
                                                    className={`switch-move-icon absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full  border-2 transition ${
                                                        preOrder ? 'border-white bg-primary-dashBlue translate-x-full' : 'bg-white border-primary-dashBlue'
                                                    }`}
                                                ></div>
                                            </div>
                                            
                                        </label>
                                    </div>

                                    {/* date pick */}
                                    <div className="w-full lg:w-4/12 px-12px">
                                        <Form.Group controlId="delivery_date">
                                            <Form.Label>Date For Pick Up</Form.Label>
                                            <Form.Control type="date" name="delivery_date" value={deliveryDate} onChange={(e) => handleDeliveryDate(e)} disabled={!preOrder} min={new Date().toISOString().split('T')[0]} />
                                        </Form.Group>
                                    </div>

                                    {/* Time For Delivery */}
                                    <div className="w-full lg:w-4/12 px-12px">
                                        <Form.Group controlId="delivery_time">
                                            <Form.Label>Time For Delivery</Form.Label>
                                            <TimePicker start={startTime} end="23:45" step={15} initialValue={deliveryTime} value={deliveryTime} onChange={setDeliveryTime} disabled={!preOrder} className="form-select" />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/4 relative self-baseline mt-auto">
                                {hasCard ? 
                                    <button type="submit" className="quickorder" id="quickorder">Driver Now</button>
                                :
                                    <button type="button" className="quickorder" id="quickorder" onClick={handleCardShow}>Driver Now</button>
                                }
                                
                            </div>
                        </div>

                        <div className="hidden sm:flex flex-col lg:flex-row gap-[20px] mt-25px -mx-12px">
                            <div className="w-full lg:w-2/3 px-12px">
                                <div className="flex justify-center dashboard-btns p-15px">
                                    <button type="button" className="scan_drivers btn-dash" onClick={() => setShowDrivers(!showDrivers)}>Scan Drivers</button>
                                    {!isRomantic && <>
                                        <button type="button" className="add_more_orders btn-dash" onClick={() => handleChangeTab('moreOrders')}>More Orders</button>
                                        <a href="/marketingads" className="btn-dash btn-text-ads">Text Ads</a>
                                    </>}
                                    
                                    <button type="button" onClick={refreshPage} className="refresh_button btn-dash">REFRESH</button>
                                </div>
                            </div>

                            <div className="w-full lg:w-1/3 px-12px pt-2.5">
                                <Slider min={0} value={rangeVal} marks={{ 0: 0, 2: 2, 4: 4, 6: 6, 8: 8, 10: 10 }} step={1} max={10} onChange={val=>handleRangeChange(val)} className="rangeSlider" />
                                <p className="text-center mb-0 text-sm mt-4 text-black">Set Radius Miles For Delivery {rangeVal} Miles</p>
                            </div>
                            
                        </div>
                    </Form>
                </div>

                {/* chat column */}
                <div className="w-full md:w-1/3 px-12px">
                    <ChatSection />
                </div>
            </div>

            {/* Scan Drivers */}
            <ScanDrivers show={showDrivers} />

            {/* Map Modal */}
            <Modal show={show} onHide={handleClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Satellite Distance <br/> {mapDistance} Miles {mapTime} Minutes <br/>Locked Wheel Movement</h4>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Latitude: {mapLat}<br/> Longitude: {mapLong}</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: 40.0583, lng: 74.4057}}
                        zoom={7}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeId: "satellite",
                        }}
                    >
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </Modal.Body>
            </Modal>

            {/* Card Modal */}
            <Modal show={showCard} onHide={handleCardClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="mb-0 text-center w-full text-black">Please enter your form of payment.</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <Form className="user-cc-form" method="post" onSubmit={handleCardSubmit}>
                        <Card className="text-center">
                            <Card.Body>
                                <Form.Group className="mb-15px" controlId="user_name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="user_name" placeholder="Enter your name" value={userName} readOnly />
                                </Form.Group>

                                <Form.Label htmlFor="card_number">Credit Card Number</Form.Label>
                                <InputGroup className="mb-15px">
                                    <Form.Control
                                        name="card_number"
                                        aria-describedby="basic-addon2"
                                        value={accountNumber}
                                        onChange={(e) => setAccountNumber(e.target.value)}
                                        required
                                        maxLength={16}
                                    />
                                    <InputGroup.Text id="basic-addon2">
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    </InputGroup.Text>
                                </InputGroup>

                                <div className="flex flex-col sm:flex-row gap-2.5">
                                    <Form.Group controlId="cc_expiration_month" className="w-full">
                                        <Form.Label>Month</Form.Label>
                                        <Form.Select name="cc_expiration_month" value={ccExpirationMonth} onChange={(e) => setCcExpirationMonth(e.target.value)} required>
                                            <option value="">MM</option>
                                            <option value="01">January</option>
                                            <option value="02">February</option>
                                            <option value="03">March</option>
                                            <option value="04">April</option>
                                            <option value="05">May</option>
                                            <option value="06">June</option>
                                            <option value="07">July</option>
                                            <option value="08">August</option>
                                            <option value="09">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group controlId="cc_expiration_year" className="w-full">
                                        <Form.Label>Year</Form.Label>
                                        <Form.Select name="cc_expiration_year" value={ccExpirationYear} onChange={(e) => setCcExpirationYear(e.target.value)} required>
                                            <option value="">YY</option>
                                            {years.map((year) => (
                                                <option key={year} value={getTwoDigitYear(year).toString().padStart(2, '0')}>
                                                {getTwoDigitYear(year).toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group controlId="cc_ccv" className="w-full">
                                        <Form.Label>CVV/CVC</Form.Label>
                                        <Form.Control type="password" name="cc_ccv" placeholder="123" value={ccCvv} onChange={(e) => setCcCvv(e.target.value)} maxLength={4} required />
                                    </Form.Group>

                                </div>

                            </Card.Body>
                            <Card.Footer className="flex gap-2.5 items-center justify-center bg-white">
                                <Button type="submit" className="card-form-btns">Add</Button>
                                <Button type="button" className="card-form-btns" onClick={handleCardReset}>Reset</Button>
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default DriverNowSection