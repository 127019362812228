import { Navbar } from "react-bootstrap"
import logo from '../../../assests/images/consumer/odds-logo-new_1.svg';
// import posImg from '../../../assests/images/consumer/personal_driver.png';

const ConsumerDashboardHeader = () => {
    return(
        <Navbar className="dashboard-navbar ">
            <div className="max-w-screen-2xl mx-auto w-full flex flex-col md:flex-row items-center pt-30px gap-2.5">
                <div className="w-full md:w-1/3 px-12px">
                    <Navbar.Brand href='/consumerdashboard' className='p-0 m-0'>
                        <img src={logo} alt='ODDS Logo' className='logo mx-0 h-[50px]' />
                    </Navbar.Brand>
                </div>
                <div className="hidden sm:block w-full md:w-2/3 px-12px">
                    <div className="header-heading-section rounded-full bg-primary-headerBlue text-white center md:text-right py-2 px-[12px] lg:px-5 text-xl lg:text-2xl max-w-700 md:ml-auto">
                        Personal Drivers <b>24 Hrs a day</b> - (Pick up anything)
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default ConsumerDashboardHeader