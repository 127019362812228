import { CLEAR_ERRORS, DRIVER_USER_DETAIL_FAIL, DRIVER_USER_DETAIL_REQUEST, DRIVER_USER_DETAIL_SUCCESS, LOGIN_DRIVER_FAIL, LOGIN_DRIVER_REQUEST, LOGIN_DRIVER_SUCCESS, REGISTER_DRIVER_ERROR, REGISTER_DRIVER_REQUEST, REGISTER_DRIVER_SUCCESS, RESET_DRIVER, UPDATE_DRIVER_REQUEST, UPDATE_DRIVER_FAIL, UPDATE_DRIVER_RESET, UPDATE_DRIVER_SUCCESS } from "../../types/driverTypes/driverUsers";

export const driverUserReducer = (state = { user: {} }, { type, payload }) => {
    switch(type){
        case LOGIN_DRIVER_REQUEST:
        case REGISTER_DRIVER_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            }
        case LOGIN_DRIVER_SUCCESS:
        case REGISTER_DRIVER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: payload,
            }
        case LOGIN_DRIVER_FAIL:
        case REGISTER_DRIVER_ERROR:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: payload,
            }
        case RESET_DRIVER:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            }
        default:
            return state;
    }
}

// Driver details
export const driverDetailsReducer = (state = { driverUserDetail: {} }, { type, payload }) => {
    switch (type) {
        case DRIVER_USER_DETAIL_REQUEST:
            return {
                loading: true,
            }
        case DRIVER_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                driverUserDetail: payload,
            }
        case DRIVER_USER_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                driverUserDetail: null,
                error: payload,
            }
        default:
            return state;
    }
}

export const driverProfileReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_DRIVER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_DRIVER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case UPDATE_DRIVER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
         case UPDATE_DRIVER_RESET:
            return {
                ...state,
                isUpdated: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};